import {
    useEffect,
    useRef,
    useState
} from "react";
import DataGrid, {
    Column,
    Export,
    KeyboardNavigation,
    Lookup,
    Pager,
    Paging,
    SearchPanel,
    Selection
} from 'devextreme-react/data-grid';
import {
    useNavigate,
    useRouteError,
} from "react-router-dom";
import { ROUTE_ADD_USER, ROUTE_EDIT_USER } from "../routes/Routes";
import GetFetch from "../hooks/GetFetch";
import CustomPreparedHeaderCell from "../components/CustomPreparedHeaderCell";
import ExportGrid from '../components/ExportGrid';
import { allowedPageSizes, pageFormats } from "../components/GlobalDataGridConfigurations";
import { logExport, useAuth } from "../hooks/useAuth";
import ClosingAlert from "../components/ClosingAlert";
import { BaseButton } from "../components/BaseButton/BaseButton";
import { CustomAccordion } from "../components/CustomAccordion/CustomAccordion";
import { DataGridNavigationInstructions } from "../CustomAccordionItemList/SelectListInstructions";
import { Content } from "../Enums/contents";


const { 
    Buttons: { 
        newUserButtonLabel, 
        editUserButtonLabel 
    } 
} = Content;

export const UserLoader = () => {
    return GetFetch('/v1/SystemUser/GetUsers');
};

export const ErrorElement = () => {
    const [open, setOpen] = useState(true)
    const error = useRouteError();
    console.error(error);

    return (
        <ClosingAlert
            severity="error"
            message="Sorry, we having trouble completing your request, please try again later..."
            visible={open}
            hideAlert={() => { setOpen(false) }}
        />
    );
}

export const LoadedPage = ({ dataIn }) => {
    const navigate = useNavigate();
    const { user } = useAuth();
    const dataFetchedRef = useRef(true);
    const [data, setData] = useState(dataIn);
    const [statuses, setStatus] = useState();
    const [roles, setRoles] = useState();
    const [editRowButton, setEditRowButton] = useState(false);
    const [selectedRowData, setSelectedRowData] = useState();
    const [selectedRowKey, setSelectedRowKey] = useState(null);
    const [customEditButtonLabel, setCustomEditButtonLabel] = useState(undefined);
    const [focusedRowIndex, setFocusedRowIndex] = useState(-1);

    useEffect(() => {
        getRoles();
        getStatuses();
    }, []);

    useEffect(() => {
        if (dataFetchedRef.current) return;
        dataFetchedRef.current = true;
        handleSettingData();
    }, [dataFetchedRef.current]);
      
    const getRoles = async () => {
        const res = await GetFetch('/v1/SystemRoles/GetRoles');
        const { Message, Success, Errors } = res;

        if (Success) {
            setRoles(Message);
        };

        if (Errors.length > 0) {
            setErrorMessage(res.Errors[0].Message);
            console.log("What went wrong:", Errors);
        };
    }
    
    const employeeTypes = ["Contractor", "Federal"];
 
    const getStatuses = async () => {
        const res = await GetFetch('/v1/SystemUser/GetUserStatuses');
        const { Message, Success, Errors } = res;

        if (Success) {
            setStatus(Message);
        };

        if (Errors.length > 0) {
            setErrorMessage(res.Errors[0].Message);
            console.log("What went wrong:", Errors);
        };
    };

    const handleSettingData = async () => {
        const request = await GetFetch('/v1/SystemUser/GetUsers');
        const { Message, Success, Errors } = request;

        if (Success) {
            setData(Message);
        };

        if (Errors.length > 0) {
            console.error(Errors);
        };
    };

    const dataSource = data.map(
        (
            {
                EDIPI,
                ContractStartDate,
                ContractEndDate,
                ContractorCompany,
                CreatedBy,
                CreatedDate,
                EmailAddress,
                EmployeeType,
                JobTitle,
                RegionNumber,
                Regions,
                SeparationDate,
                UpdatedBy,
                UpdatedDate,
                UserAccountName,
                UserStatus,
                UserStatusId,
                UserRoleId,
                TieIsOverride,
                Role: { Name }
            }
        ) => {
            if (Regions) {
                Regions.sort((a, b) => {
                    return a - b
                });
            }
            return {
                EDIPI: EDIPI ?? 'N/A',
                contractStartDate: ContractStartDate ?? '',
                contractEndDate: ContractEndDate ?? '',
                contractorCompany: ContractorCompany || 'N/A',
                createdBy: CreatedBy ?? '',
                createdDate: CreatedDate ?? '',
                emailAddress: EmailAddress || '',
                employeeType: EmployeeType || '',
                jobTitle: JobTitle || 'N/A',
                regionNumber: RegionNumber ?? '',
                regions: Regions ?? '',
                separationDate: SeparationDate ?? '',
                updatedBy: UpdatedBy ?? '',
                updatedDate: UpdatedDate,
                userAccountName: UserAccountName ?? '',
                userStatus: UserStatus ?? '',
                userStatusId: UserStatusId ?? '',
                userRoleId: UserRoleId ?? '',
                tieIsOverride: TieIsOverride ?? '',
                roleName: Name ?? '',
            };
        }
    );
    const pdfFont = ()=>
    {    return 7;   }
    const wrapPdfCell = ()=>{
     return true;   }

    const handleExport = (e) => {
        logExport(user.email)
        ExportGrid(e, "Users", wrapPdfCell(), pdfFont())
    };

    const handleAddButtonClick = () => {
        navigate(ROUTE_ADD_USER.withSlash)
    };
    
    const handleEditButtonClick = () => {
        navigate(ROUTE_EDIT_USER.withSlash, {
            state: { selectedRowData }
        })  
    };

    const handleEnterKeyPress = (e) => {
        const key = e.event?.originalEvent?.key;

        if (key === "Enter") {
            setCustomEditButtonLabel(selectedRowKey?.emailAddress)
            setEditRowButton(true);
            setSelectedRowData(selectedRowKey);
        }
    };

    const handleFocusedRowChange = (e) => {
        if (e.row.isSelected === false) setFocusedRowIndex(e.rowIndex);
        
        const { data } = e.row;
        setSelectedRowKey(data);
        setCustomEditButtonLabel(data.emailAddress);
        setSelectedRowData(data);
    };
    
    const handleRowClick = ({ data }) => {
        setSelectedRowKey(data);
        setEditRowButton(true);
        setCustomEditButtonLabel(data.emailAddress);
        setSelectedRowData(data);
    };
    
    const buttonOutlineStyle = "outlined";
    const isUserRecordSelected = !editRowButton ? true : false;
    const editButtonLabel = customEditButtonLabel ? `Edit ${customEditButtonLabel}` : editUserButtonLabel;

    return (
        <>
            <div style={{ marginBottom: "1rem" }}>
                <BaseButton 
                    label={newUserButtonLabel}
                    onClick={handleAddButtonClick}
                    variant={buttonOutlineStyle}
                />
                <BaseButton
                    id={selectedRowKey?.EDIPI ? `edit-button-${selectedRowKey.EDIPI}` : 'edit-button'}
                    ariaDisabled={isUserRecordSelected}
                    disabled={isUserRecordSelected}
                    ariaLabel={
                        customEditButtonLabel 
                            ? `Selected Row ${focusedRowIndex + 1}, email ${customEditButtonLabel}` 
                            : editUserButtonLabel
                    }
                    label={editButtonLabel}
                    onClick={handleEditButtonClick}
                    variant={buttonOutlineStyle}
                />
            </div>
            <DataGrid
                columnAutoWidth={true}
                dataSource={dataSource}
                focusedRowEnabled={true}
                keyExpr="EDIPI"
                keyboardNavigation={{
                    enabled: true,
                    enterKeyAction: 'none',
                    enterKeyDirection: 'none'
                }}
                onCellPrepared={CustomPreparedHeaderCell}
                onFocusedRowChanged={handleFocusedRowChange}
                onExporting={e => handleExport(e)}
                onKeyDown={handleEnterKeyPress}
                onRowClick={handleRowClick}
                rowAlternationEnabled={true}
                showBorders={true}
                showColumnLines={true}
                showRowLines={true}
            >
                <KeyboardNavigation enabled={true} />
                <SearchPanel visible={true} highlightCaseSensitive={true} />
                <Selection mode="single" />
                <Paging defaultPageSize={10} />
                <Pager
                    showPageSizeSelector={true}
                    allowedPageSizes={allowedPageSizes}
                />
                <Column
                    caption="Account Name"
                    dataField="userAccountName"
                    />                            
                <Column
                    caption="Role"
                    dataField="userRoleId"
                >
                    <Lookup
                        dataSource={roles}
                        displayExpr="Name"
                        valueExpr="RoleId"
                    />
                </Column>
                <Column
                    caption="Status"
                    dataField="userStatusId"
                >
                    <Lookup
                        dataSource={statuses}
                        displayExpr="Name"
                        valueExpr="StatusId"
                    />
                </Column>
                <Column
                    caption="Regions"
                    dataField="regions"
                />
                <Column
                    caption="Employee Type"
                    dataField="employeeType"
                    displayExpr="text"
                    valueExpr="value" 
                >
                    <Lookup
                        dataSource={employeeTypes}
                    />
                </Column>
                <Column
                    caption="Job Title"
                    dataField="jobTitle"
                />                          
                <Column
                    caption="Contractor Company"
                    dataField="contractorCompany"
                />                             
                <Column
                    caption="Contract Start Date"
                    dataField="contractStartDate"
                    dataType="date"
                />
                <Column
                    caption="Contract End Date"
                    dataField="contractEndDate"
                    dataType="date"
                />
                <Column
                    caption="Separation Date"
                    dataField="separationDate"
                    dataType="date"
                />
                <Column
                    caption="Created Date"
                    dataField="createdDate"
                    dataType="date"
                />
                <Column
                    caption="Created By"
                    dataField="createdBy"
                />
                <Column
                    caption="Updated Date"
                    dataField="updatedDate"
                    dataType="date"
                />
                <Column
                    caption="Updated By"
                    dataField="updatedBy"
                />
                <Column
                    caption="Override TIE"
                    dataField="tieIsOverride"
                />
                <Export
                    enabled={true}
                    formats={pageFormats}
                />
            </DataGrid>
            <CustomAccordion itemList={< DataGridNavigationInstructions />} />
        </>
    );
};