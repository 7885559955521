import { Button } from "devextreme-react";
import DataGrid, {
    Column,
    FilterRow,
    Item,
    KeyboardNavigation,
    Pager,
    Paging,
    SearchPanel,
    Toolbar,
} from 'devextreme-react/data-grid';
import CustomPreparedHeaderCell from "../../components/CustomPreparedHeaderCell";
import { allowedPageSizes } from "../../components/GlobalDataGridConfigurations";
import { ROUTE_CREATE_EDIT_POST_EXHIBIT, ROUTE_WELCOME } from "../../routes/Routes";
import { useNavigate } from "react-router-dom";

export default function DataGridPostExhibits({ dataSource, isCostEstimate  }) {
    const navigate = useNavigate();
    const navigateToHomePage = () => navigate(ROUTE_WELCOME.withSlash);

    const onBackClick = () => {
        navigateToHomePage();
    };

    const navigateToEditPostExhibit = (postExhibitId) => {
        navigate(ROUTE_CREATE_EDIT_POST_EXHIBIT.withSlash, {
            state: {
                postExhibitId: postExhibitId,
                isCostEstimate

            }
        });
    }

    return (
        <DataGrid
            columnAutoWidth={true}
            dataSource={dataSource}
            onCellPrepared={CustomPreparedHeaderCell}
            showBorders={true}
            showRowLines={true}
            rowAlternationEnabled={true}
        >
            <FilterRow visible={true} />
            <KeyboardNavigation enabled={true} />
            <Paging defaultPageSize={10} />
            <Pager
                showPageSizeSelector={true}
                allowedPageSizes={allowedPageSizes}
            />
            <SearchPanel
                visible={true}
                highlightCaseSensitive={true}
            />
            <Toolbar>
                <Item
                    name="searchPanel"
                    locateInMenu="auto"
                />
                <Item location='after'>
                    <Button text='Home' icon='home' stylingMode='outlined' type='normal' onClick={onBackClick} />
                </Item>
            </Toolbar>
            <Column
                dataField="PostExhibitId"
                caption="PostExhibit ID"
                cellRender={(cellData) => (
                    <a
                        href="#"
                        onClick={(e) => {
                            e.preventDefault();
                            navigateToEditPostExhibit(cellData.value)
                        }}
                    >
                        Edit: {cellData.value}
                    </a>
                )}
            />
            <Column dataField="Name" caption="Name" />
            <Column dataField="TaskOrderId" caption="TaskOrder ID" />
            <Column dataField="VendorName" caption="Vendor" />
            <Column dataField="ContractId" caption="Contract Number" />
            <Column dataField="RegionId" caption="Region" />
            <Column dataField="FiscalYear" caption="Fiscal Year" />
            <Column dataField="PostExhibitStatus.Name" caption="Status" />
            <Column dataField="StartDate" caption="Start Date" />
            <Column dataField="EndDate" caption="End Date" />
        </DataGrid>
    )
}