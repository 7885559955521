import { useEffect, useState } from "react";
import { ROUTE_OCCUPANCY_AGREEMENTS } from "../routes/Routes";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import { BaseSelectField } from "../components/BaseSelectField/BaseSelectField";
import { LoadingIndicatorCircle } from "../components/Loading";
import GetFetch from "../hooks/GetFetch";

const OccupancyAgreementPage = () => {
    const { title } = ROUTE_OCCUPANCY_AGREEMENTS;
    const [customers, setCustomers] = useState([]);
    const [selectedCustomer, setSelectedCustomer] = useState(null);
    const navigate = useNavigate();
    const { id } = useParams();
    useEffect(() => {
        document.title = title;
        if (customers.length < 1) {
            fetchCustomers();
        }
        if (id) {
            const idToInt = parseInt(id);
            const customerResult = customers.find(c => c.CustomerId === idToInt);
            // console.log(customerResult)
            if (customerResult !== undefined) {
                setSelectedCustomer(customerResult)
            }
        }
    }, []);
    useEffect(() => {
        // console.log(id);
        if (!selectedCustomer && id && customers.length > 0) {
            // console.log("customers:", customers)
            const idToInt = parseInt(id);
            const customerResult = customers.find(c => c.CustomerId === idToInt);
            // console.log(customerResult)
            if (customerResult !== undefined) {
                setSelectedCustomer(customerResult)
            }
        }
    }, [customers, id])
    useEffect(() => {
        if (selectedCustomer) {
            navigate(`/Occupancy-Agreements/${selectedCustomer.CustomerId}/list`)
        }
    }, [selectedCustomer]);

    const fetchCustomers = async () => {
        const { Success, Message, Errors } = await GetFetch('/v1/Customer/GetCustomersNameAndId');

        if (Success) {
            setCustomers(Message);
        }
        else {
            setError(Errors[0].Message);
        }
    }

    return (
        <>
            <h1>{title}</h1>
            <div>
                <em>Select a Customer to view/edit/add Occupancy Agreements</em>
            </div>
            {customers.length > 0 ?
                <BaseSelectField
                    data={customers}
                    enableSearch={true}
                    inputAttribute={{ "aria-label": "Select a Customer Dropdown Menu" }}
                    label={selectedCustomer ? "Customer" : "Select a Customer"}
                    labelMode={"floating"}
                    optionsLabel={"AgencyName"}
                    optionUpdatedByUser={({ selectedItem }) => setSelectedCustomer(selectedItem)}
                    value={selectedCustomer}
                    validationRuleCallback={() => true}
                />
                :
                <LoadingIndicatorCircle message={"loading customers..."} />
            }
            <Outlet />
        </>
    )
}
export default OccupancyAgreementPage;