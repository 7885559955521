import React, { useState, useEffect, useRef, useCallback } from "react";
import { BaseButton } from "../../components/BaseButton/BaseButton";
import { FormTextBox } from '../../components/FormTextBox/FormTextBox';
import { ROUTE_CONTRACT_COST_ESTIMATES, ROUTE_CREATE_EDIT_POST_EXHIBIT, ROUTE_POST_EXHIBITS_LIST_PAGE } from "../../routes/Routes";
import "../css/PostExhibits.scss";
import { useLocation, useNavigate } from "react-router-dom";
import { Form, Item as FormItem, GroupItem, TabbedItem, TabPanelOptions, Tab, Item, RequiredRule, CustomRule } from "devextreme-react/form";
import GetFetch from "../../hooks/GetFetch";
import { DateBox, SelectBox, TextBox, ValidationGroup, Validator } from "devextreme-react";
import DataGrid, {
    Column,
    FilterRow,
    Item as DataGridItem,
    KeyboardNavigation,
    Pager,
    Paging,
    SearchPanel,
    Toolbar,
} from 'devextreme-react/data-grid';
import { ToolbarForm } from "../../components/toolbar-form/toolbar-form";
import DataGridPost from "./DataGridPost";
import PutPostPatchFetch from "../../hooks/PutPostPatchFetch";
import { FormDateBox } from "../../components/FormDateBox/FormDateBox";
import IsNullOrEmpty from "../../components/IsNullOrEmpty";
import notify from "devextreme/ui/notify";
import { LoadingPanel } from "../../components/LoadingPanel";
import PersonnelTypeIds from "../../Enums/personnelTypeIds";
import { BaseCheckBox } from "../../components/BaseCheckBox/BaseCheckBox";
import { fetchPostExhibits } from "../PostExhibitsService";
import CustomPreparedHeaderCell from "../../components/CustomPreparedHeaderCell";
import { allowedPageSizes } from "../../components/GlobalDataGridConfigurations";
import { getPostExhibitStatusIdByName } from "../../Enums/postExhibitStatuses";


const URL_contracts = '/v1/Contract/GetContracts';
const URL_clonePostExhibit = '/v1/PostExhibit/ClonePostExhibit/'; //+ PostExhibitId
const URL_updatePostExhibit = '/v1/PostExhibit/UpdatePostExhibit';
const URL_addPostExhibit = '/v1/PostExhibit/AddPostExhibit';
const URL_getPostExhibitStatuses = '/v1/PostExhibit/GetPostExhibitStatuses';
const URL_getPostExhibitsById = '/v1/PostExhibit/GetPostExhibitById/'; //+PostExhbitId
const URL_feeTypes = '/v1/FeeType/GetFeeTypes';
const URL_postStatuses = '/v1/PostStatus/GetPostStatus';

/** @type {import("../../types/postExhibit").PostExhibit} */
const defaultPostExhibit = {
    PostExhibitId: 0,
    Posts: []
}

/** @typedef {import('../../../src/types/postExhibit').PostExhibit} PostExhibit */

export default function PostExhibitForm() {
    const navigate = useNavigate();
    const location = useLocation();
    const [taskOrders, setTaskOrders] = useState([]);
    const [contracts, setContracts] = useState([]);
    const [selectedContractId, setSelectedContractId] = useState(null);
    const [selectedTaskOrderNumber, setSelectedTaskOrderNumber] = useState(null);
    const [editing, setEditing] = useState(false);
    const [isNewPostExhibit, setIsNewPostExhibit] = useState(false);
    const dataRef = useRef(null);
    const postDataRef = useRef(null);
    const [postExhibitFormData, setPostExhibitFormData] = useState(defaultPostExhibit);
    const [isCostEstimate, setIsCostEstimate] = useState(false);
    const [postExhibitStatuses, setPostExhibitStatuses] = useState(null);
    /**@type {[PostExhibit, React.Dispatch<React.SetStateAction<PostExhibit>>]} */
    const [postExhibit, setPostExhibit] = useState(null);
    const [feeTypes, setFeeTypes] = useState(null);
    const [postStatuses, setPostStatuses] = useState(null);
    const [loading, setLoading] = useState(true);
    const [costEstimates, setCostEstimates] = useState(null);
    const validationGroupRef = useRef(null);

    const minDate = new Date(1900, 0, 1);

    const isFormChanged = (original, current) => {
        return JSON.stringify(original) !== JSON.stringify(current);
    };

    useEffect(() => {
        const state = location?.state;
        fetchDataAndInitialize(state);
    }, []);

    const fetchDataAndInitialize = async (state) => {
        setLoading(true);

        const initialData = await initializeStateData(state);
        setFormState(initialData);

        await loadDropdownData();

        if (initialData.contractId) {
            populateTaskOrders(initialData.contractId);
        }

        setLoading(false);
    };

    const initializeStateData = async (state) => {
        if (!state) {
            return {
                isEditing: true,
                isNewPostExhibit: true,
                postExhibitFormData: defaultPostExhibit,
            };
        }

        const { isEditing, isNewPostExhibit, postExhibitId, postExhibit, postExhibitFormData, isCostEstimate } = state;
        let savedPostExhibit = null;
        if (!IsNullOrEmpty(postExhibitId) && postExhibitId !== 0) {
            savedPostExhibit = await fetchData(`${URL_getPostExhibitsById}${postExhibitId}`);
        }

        const resolvedPostExhibit = postExhibit ?? savedPostExhibit ?? null;
        let currentPostExhibitForm = mergeFormData(resolvedPostExhibit, postExhibitFormData);

        return {
            postExhibit: resolvedPostExhibit,
            postExhibitFormData: currentPostExhibitForm,
            contractId: currentPostExhibitForm?.TaskOrder?.ContractId || null,
            taskOrderNumber: currentPostExhibitForm?.TaskOrderNumber || null,
            isEditing: isEditing ?? false,
            isNewPostExhibit: isNewPostExhibit ?? false,
            isCostEstimate: isCostEstimate ?? false,
        };
    };

    const mergeFormData = (resolvedPostExhibit, statePostExhibitFormData) => {
        if (!resolvedPostExhibit && statePostExhibitFormData) return statePostExhibitFormData;
        if (resolvedPostExhibit && !statePostExhibitFormData) return resolvedPostExhibit;

        if (isFormChanged(resolvedPostExhibit, statePostExhibitFormData)) {
            return {
                ...resolvedPostExhibit,
                ...Object.keys(statePostExhibitFormData).reduce((acc, key) => {
                    if (!IsNullOrEmpty(statePostExhibitFormData[key])) {
                        acc[key] = statePostExhibitFormData[key];
                    }
                    return acc;
                }, {}),
                Posts: Array.isArray(statePostExhibitFormData?.Posts) && statePostExhibitFormData.Posts.length > 0
                    ? statePostExhibitFormData.Posts
                    : resolvedPostExhibit?.Posts ?? [],
            };
        }

        return resolvedPostExhibit;
    };

    const setFormState = ({ postExhibit, postExhibitFormData, contractId, taskOrderNumber, isEditing, isNewPostExhibit, isCostEstimate }) => {
        setPostExhibit(postExhibit);
        setPostExhibitFormData(postExhibitFormData);
        setIsCostEstimate(isCostEstimate);
        setSelectedContractId(contractId);
        setSelectedTaskOrderNumber(taskOrderNumber);
        setEditing(isEditing);
        setIsNewPostExhibit(isNewPostExhibit);
    };

    const loadDropdownData = async () => {
        const results = await Promise.allSettled([
            fetchData(URL_getPostExhibitStatuses),
            fetchData(URL_contracts),
            fetchData(URL_feeTypes),
            fetchData(URL_postStatuses),
        ]);

        setPostExhibitStatuses(results[0].status === "fulfilled" ? results[0].value : []);
        setContracts(results[1].status === "fulfilled" ? results[1].value : []);
        setFeeTypes(results[2].status === "fulfilled" ? results[2].value : []);
        setPostStatuses(results[3].status === "fulfilled" ? results[3].value : []);
    };

    const populateTaskOrders = (contractId) => {
        const contract = contracts.find((c) => c.ContractId === contractId);
        if (contract?.TaskOrders) {
            setTaskOrders(contract.TaskOrders);
        }
    };

    useEffect(() => {
        if (selectedContractId && contracts.length > 0) {
            const contract = contracts.find(c => c.ContractId === selectedContractId);
            if (contract?.TaskOrders) {
                setTaskOrders(contract.TaskOrders);
            }
        }
    }, [selectedContractId, contracts]);

    useEffect(() => {
        if (!IsNullOrEmpty(selectedContractId) && !IsNullOrEmpty(postExhibitFormData?.TaskOrder?.TaskOrderNumber)) {
            setSelectedTaskOrderNumber(postExhibitFormData.TaskOrder.TaskOrderNumber);
        }
    }, [selectedContractId, postExhibitFormData]);

    const fetchData = async (url) => {
        const { Message, Success } = await GetFetch(url);
        return Success ? Message : [];
    }

    const updateField = useCallback((field, value) => {
        setPostExhibitFormData((prevData) => ({
            ...prevData,
            [field]: value,
        }));
    }, []);


    const handleContractSelection = useCallback((selectedItem) => {
        if (selectedItem && selectedItem.ContractId !== selectedContractId) {
            setSelectedContractId(selectedItem.ContractId);
            const contract = contracts.find(contract => contract.ContractId === selectedItem.ContractId);
            if (contract && contract.TaskOrders) {
                setTaskOrders(contract.TaskOrders);
            } else {
                setTaskOrders([]);
            }

            updateField('ContractId', selectedItem.ContractId);
        }
    }, [contracts, selectedContractId, updateField]);

    const handleTaskOrderSelection = useCallback((selectedItem) => {
        if (selectedItem && selectedItem.Number !== selectedTaskOrderNumber) {
            setSelectedTaskOrderNumber(selectedItem.Number);
            updateField('TaskOrderNumber', selectedItem.Number);
            updateField('TaskOrderId', selectedItem.TaskOrderId);
            updateField('TaskOrder', selectedItem);
        }
    }, [selectedTaskOrderNumber, updateField]);

    const onClonePostExhibit = async () => {
        try {
            const { Errors, Message, Success } = await GetFetch(`${URL_clonePostExhibit}${postExhibitFormData.PostExhibitId}`);
            if (Success) {
                const clonedPostExhibitId = Message.PostExhibitId;
                showMessage(`Success! Cloned Post Exhibit ID: ${clonedPostExhibitId}`, true);
            } else {
                showMessage('Error cloning post exhibit', false);
            }
        } catch (error) {
            console.error('Error cloning post exhibit:', error);
            showMessage('An unexpected error occurred while cloning', false);
        }
    }

    const showMessage = (message, success, displayTime = 3000, width = 500) => {
        notify(
            {
                message: message,
                width: width,
                position: {
                    at: 'top',
                    my: 'top',
                    of: '#container'
                }
            },
            success ? "success" : 'error',
            displayTime
        )
    }

    const formatForSave = (obj) => {
        // Handle arrays to prevent conversion to objects
        if (Array.isArray(obj)) {
            return obj.map(item => formatForSave(item));
        }

        const formattedObj = { ...obj };

        if (!Object.hasOwn(formattedObj, 'PostExhibitId')) {
            formattedObj.PostExhibitId = 0;
        }

        // Remove ClonePostExhibitId if null
        if (Object.hasOwn(formattedObj, 'ClonedPostExhibitId') && formattedObj.ClonedPostExhibitId === null) {
            delete formattedObj.ClonedPostExhibitId;
        }

        // Replace null IDs with 0
        Object.keys(formattedObj).forEach(key => {
            if (key.toLowerCase().endsWith('id') && formattedObj[key] === null) {
                formattedObj[key] = 0;
            }
        });

        // Remove TaskOrder object
        if (formattedObj.TaskOrder) {
            formattedObj.TaskOrder = null;
        }
        // Remove Building Object
        if (formattedObj.Building) {
            formattedObj.Building = null;
        }

        // Handle nested objects
        Object.keys(formattedObj).forEach(key => {
            if (typeof formattedObj[key] === 'object' && formattedObj[key] !== null) {
                formattedObj[key] = formatForSave(formattedObj[key]);
            }
        });

        return formattedObj;
    }
    const refetchPostExhibitData = async () => {
        if (!postExhibit?.PostExhibitId) return;

        try {
            const updatedData = await fetchData(`${URL_getPostExhibitsById}${postExhibit.PostExhibitId}`);
            if (updatedData) {
                setPostExhibit(updatedData);
                setPostExhibitFormData(updatedData);
            }
        } catch (error) {
            console.error("Error fetching updated Post Exhibit:", error);
        }
    };

    const onSaveClick = async () => {
        if (!validationGroupRef.current) {
            console.error("ValidationGroup ref is not set");
            return;
        }

        const validationResult = validationGroupRef.current.instance.validate();

        if (!validationResult.isValid) {
            showMessage('Please complete and correct all required fields!', false)
            return;
        }

        const preparedPostExhibitData = formatForSave(postExhibitFormData);
        setEditing(false);

        try {
            const isNewPostExhibit = IsNullOrEmpty(preparedPostExhibitData.PostExhibitId) || preparedPostExhibitData.PostExhibitId === 0;
            const saveUrl = isNewPostExhibit ? URL_addPostExhibit : URL_updatePostExhibit;
            const { Success, Errors, Message } = await PutPostPatchFetch(
                saveUrl,
                'POST',
                preparedPostExhibitData
            );

            if (Success) {
                showMessage(
                    isNewPostExhibit ? 'New Post Exhibit created successfully' : 'Post Exhibit updated successfully',
                    Success,
                    3000
                );

                if (isNewPostExhibit) {
                    navigate(ROUTE_POST_EXHIBITS_LIST_PAGE.withSlash);
                } else {
                    refetchPostExhibitData();
                }
            } else {
                showMessage('Failed to save Post Exhibit: ' + Errors[0].Message, false, 5000);
                setEditing(true);
            }
        } catch (error) {
            showMessage('An unexpected error occurred while saving', false, 5000);
            setEditing(true);
            setError(true);
        }
    }

    const handleEditClick = () => {
        if (editing === false && postExhibitFormData) {
            dataRef.current = postExhibitFormData;
        } else {
            dataRef.current = undefined;
        }
        setEditing(!editing);
    }

    const onCancelClick = () => {
        setPostExhibitFormData(postExhibit);
        handleEditClick();
    }

    const getTaskOrderTypeString = () => {
        const taskOrderTypeId = postExhibitFormData?.TaskOrder?.TaskOrderTypeId || 0;
        switch (taskOrderTypeId) {
            case 1:
                return "Base";
            case 2:
                return "Option";
            case 3:
                return "Modification";
            default:
                return "";
        }
    }

    const formatDate = (date) => {
        if (!date) return null;
        const formattedDate = new Date(date);
        return formattedDate.toISOString().split('T')[0];
    }

    const navigateToListPage = () => {
        navigate(
            isCostEstimate ? ROUTE_CONTRACT_COST_ESTIMATES.withSlash : ROUTE_POST_EXHIBITS_LIST_PAGE.withSlash
        );
    }

    const getTitle = () => {
        if (isCostEstimate) return "Cost Estimate Edit";
        return isNewPostExhibit ? "Post Exhibit Add" : "Post Exhibit Edit";
    }

    const fetchAndSetEstimates = async (postExhibitId) => {
        if (!postExhibitId) return;

        try {
            const data = await fetchPostExhibits();
            const costEstimates = data?.filter(
                p => p.PostExhibitStatus?.PostExhibitStatusId === getPostExhibitStatusIdByName('Draft') &&
                    p.ClonedPostExhibitId === postExhibitId);
            setCostEstimates(costEstimates);
        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        if (postExhibit?.PostExhibitId) {
            fetchAndSetEstimates(postExhibit.PostExhibitId);
        }
    }, [postExhibit?.PostExhibitId]);

    const promoteToMain = async (postExhibitId) => {
        const estimateToPromote = isCostEstimate ? postExhibit : costEstimates.find(p => p.PostExhibitId === postExhibitId);
        if (!estimateToPromote) {
            console.error("Post Exhibit not found!");
            return;
        }

        const updatedEstimate = { ...estimateToPromote, PostExhibitStatusId: getPostExhibitStatusIdByName('Active') };
        try {
            const { Success, Errors } = await PutPostPatchFetch(
                URL_updatePostExhibit, 'POST', formatForSave(updatedEstimate)
            );

            if (!Success) {
                console.error("Failed to promote Cost Estimate:", Errors[0]?.Message);
                showMessage("Promotion failed", false, 5000);
                return;
            }

            showMessage("Cost Estimate promoted successfully!", true, 3000);
            isCostEstimate ?
                navigate(ROUTE_CREATE_EDIT_POST_EXHIBIT.withSlash, {
                    state: {
                        postExhibitId: postExhibitId
                    }
                })
                : fetchAndSetEstimates();
        } catch (error) {
            console.error("Unexpected error during promotion:", error);
            showMessage("An unexpected error occurred. Please try again.", false, 5000);
        }
    }

    return (
        <div className='postExhibit-form'>
            <h1>{getTitle()}</h1>
            {!loading && !isNewPostExhibit &&
                (
                    isCostEstimate ?
                        (postExhibit?.PostExhibitStatus?.PostExhibitStatusId === getPostExhibitStatusIdByName('Draft') &&
                            <BaseButton
                                label={"Promote To Main"}
                                onClick={promoteToMain}
                                variant={"contained"}
                                disabled={editing}
                            />) :
                        <BaseButton
                            label={"Clone Post Exhibit"}
                            onClick={onClonePostExhibit}
                            variant={"contained"}
                            disabled={editing}
                        />
                )
            }
            {loading && <LoadingPanel location={{ of: '#postExhibit-form' }} message='Loading Details' />}
            <ValidationGroup ref={validationGroupRef}>
                <ToolbarForm
                    toggleEditing={handleEditClick}
                    onSaveClick={onSaveClick}
                    onBackClick={navigateToListPage}
                    editing={editing}
                    onCancelClick={onCancelClick} />
                <Form>
                    <GroupItem
                        caption={"Post Exhibit ID: " + postExhibitFormData?.PostExhibitId} >
                        <TabbedItem >
                            <TabPanelOptions deferRendering={false} />
                            <Tab title="Contract Summary" >
                                {/* Contract Number, contract Personnel*/}
                                <GroupItem colCount={4}>
                                    {!loading &&
                                        <FormItem>
                                            <SelectBox
                                                inputAttr={{ 'aria-label': 'ContractId' }}
                                                label='Contract Id'
                                                dataSource={contracts}
                                                displayExpr={'Number'}
                                                valueExpr={'ContractId'}
                                                value={selectedContractId}
                                                onSelectionChanged={(e) => handleContractSelection(e.selectedItem)}
                                                readOnly={!editing}
                                                searchEnabled={true}
                                            />
                                        </FormItem>
                                    }
                                    {postExhibitFormData?.ContractPersonnel && postExhibitFormData.ContractPersonnel.map((personnel, index) => {
                                        return (
                                            <GroupItem colCount={'auto'} key={index}>
                                                {!loading &&
                                                    <FormItem colSpan={'auto'} key={index + 1}>
                                                        <div><b>{PersonnelTypeIds.getShortNameById(personnel.PersonnelTypeId)}</b></div>
                                                    </FormItem>
                                                }
                                                {!loading &&
                                                    <FormItem key={index + 2}>
                                                        <FormTextBox
                                                            label='Officer Full Name'
                                                            value={personnel.OfficerFullName}
                                                            isEditing={false}
                                                        />
                                                    </FormItem>
                                                }
                                            </GroupItem>
                                        )
                                    })}
                                </GroupItem>
                            </Tab>
                            <Tab title="Task Order Summary" >
                                {/* T.O Number, start date, end date, vendor, region, type*/}
                                <GroupItem colCount={3}>
                                    <FormItem>
                                        <SelectBox
                                            inputAttr={{ 'aria-label': 'Task Order Number' }}
                                            label='Task Order Number'
                                            dataSource={taskOrders}
                                            displayExpr={'Number'}
                                            valueExpr={'Number'}
                                            value={selectedTaskOrderNumber}
                                            onSelectionChanged={(e) => handleTaskOrderSelection(e.selectedItem)}
                                            readOnly={!editing}
                                            searchEnabled={true}
                                            disabled={!taskOrders.length}
                                        />
                                    </FormItem>
                                    <FormItem>
                                        <FormDateBox
                                            label='Start Date'
                                            value={postExhibitFormData?.TaskOrder?.StartDate}
                                            readOnly={true}
                                            onValueChange={e => {
                                                const formattedDate = formatDate(e)
                                                updateField('TaskOrder.StartDate', formattedDate)
                                            }}
                                        />
                                    </FormItem>
                                    <FormItem>
                                        <FormDateBox
                                            label='End Date'
                                            value={postExhibitFormData?.TaskOrder?.EndDate}
                                            readOnly={true}
                                            onValueChange={e => {
                                                const formattedDate = formatDate(e)
                                                updateField('TaskOrder.EndDate', formattedDate)
                                            }}
                                        />
                                    </FormItem>
                                    <FormItem>
                                        <FormTextBox
                                            label='Region'
                                            value={postExhibitFormData?.TaskOrder?.RegionId?.toString()}
                                            isEditing={false}
                                            onValueChange={e => updateField('TaskOrder.RegionId', e)}
                                        />
                                    </FormItem>
                                    <FormItem>
                                        <FormTextBox
                                            label='Type'
                                            value={getTaskOrderTypeString()}
                                            isEditing={false}
                                            onValueChange={e => updateField('TaskOrder.TaskOrderTypeId', e)}
                                        />
                                    </FormItem>
                                    <FormItem>
                                        <FormTextBox
                                            label='Vendor'
                                            value={postExhibitFormData?.TaskOrder?.VendorName}
                                            isEditing={false}
                                            onValueChange={e => updateField('TaskOrder.VendorName', e)}
                                        />
                                    </FormItem>
                                </GroupItem>
                            </Tab>
                            <Tab title={isCostEstimate ? 'Cost Estimate' : 'Post Exhibit'} >
                                <GroupItem colCount={3}>
                                    <FormItem>
                                        <TextBox
                                            label='Name'
                                            value={postExhibitFormData?.Name}
                                            readOnly={!editing}
                                            onValueChange={e => updateField('Name', e)}
                                        />
                                    </FormItem>
                                    <FormItem>
                                        <DateBox
                                            type='datetime'
                                            label='Start Date'
                                            showDropDownButton={true}
                                            min={minDate}
                                            value={postExhibitFormData?.StartDate}
                                            onValueChanged={e => {
                                                const formattedDate = formatDate(e.value)
                                                updateField('StartDate', formattedDate)
                                            }}
                                            displayFormat={"MM/dd/yyyy"}
                                            placeholder='MM/dd/yyyy'
                                            readOnly={!editing}
                                            useMaskBehavior={true}
                                        >
                                            <Validator>
                                                <RequiredRule message="Start Date is required" />
                                            </Validator>
                                        </DateBox>
                                    </FormItem>
                                    <FormItem>
                                        <DateBox
                                            type='datetime'
                                            label='End Date'
                                            showDropDownButton={true}
                                            min={minDate}
                                            value={postExhibitFormData?.EndDate}
                                            isEditing={editing}
                                            onValueChanged={e => {
                                                const formattedDate = formatDate(e.value)
                                                updateField('EndDate', formattedDate)
                                            }}
                                            displayFormat={"MM/dd/yyyy"}
                                            placeholder='MM/dd/yyyy'
                                            readOnly={!editing}
                                            useMaskBehavior={true}>
                                            <Validator>
                                                <RequiredRule message="End Date is required" />
                                                <CustomRule
                                                    message="End Date must be after Start Date"
                                                    validationCallback={() => {
                                                        if (!postExhibitFormData?.StartDate || !postExhibitFormData?.EndDate) {
                                                            return true;
                                                        }
                                                        return postExhibitFormData.EndDate > postExhibitFormData.StartDate;
                                                    }}
                                                />
                                            </Validator>
                                        </DateBox>
                                    </FormItem>
                                    <FormItem>
                                        <TextBox
                                            label='Fiscal Year'
                                            value={postExhibitFormData?.FiscalYear?.toString()}
                                            readOnly={!editing}
                                            onValueChange={e => updateField('FiscalYear', e)}
                                        />
                                    </FormItem>
                                    <FormItem>
                                        <SelectBox
                                            inputAttr={{ 'aria-label': 'Status' }}
                                            label='Status'
                                            dataSource={postExhibitStatuses}
                                            displayExpr={'Name'}
                                            valueExpr={'PostExhibitStatusId'}
                                            value={postExhibitFormData?.PostExhibitStatusId}
                                            onSelectionChanged={(e) => updateField('PostExhibitStatusId', e.selectedItem.PostExhibitStatusId)}
                                            readOnly={!editing || isCostEstimate}
                                            searchEnabled={true}
                                        >
                                            <Validator>
                                                <RequiredRule message="Status is required" />
                                            </Validator>
                                        </SelectBox>
                                    </FormItem>

                                    <FormItem>
                                        <FormTextBox
                                            label='Cloned Post Exhibit ID'
                                            value={postExhibitFormData?.ClonedPostExhibitId?.toString()}
                                            isEditing={false}
                                            onValueChange={e => updateField('ClonedPostExhibitId', e)}
                                        />
                                    </FormItem>
                                    <FormItem>
                                        <BaseCheckBox
                                            elementAttr={{ "aria-label": "Inflation Rate" }}
                                            value={!!postExhibitFormData?.InflationRate}
                                            onCheckedValueChanged={({ value }) => updateField('InflationRate', value)}
                                            text="Inflation Rate"
                                            name="InflationRate"
                                            readOnly={!editing}
                                        />
                                    </FormItem>
                                </GroupItem>
                            </Tab>
                        </TabbedItem>
                    </GroupItem>
                    <TabbedItem>
                        <Tab title="Posts">
                            {!loading &&
                                <GroupItem >
                                    <DataGridPost
                                        postExhibit={postExhibit}
                                        postExhibitFormData={postExhibitFormData}
                                        feetypes={feeTypes}
                                        postStatuses={postStatuses}
                                        editing={editing}
                                        postDataRef={postDataRef}
                                        updateField={updateField}
                                        isCostEstimate={isCostEstimate}
                                    />
                                </GroupItem>
                            }
                        </Tab>
                        {!isCostEstimate &&
                            <Tab title="Cost Estimates" >
                                <DataGrid
                                    columnAutoWidth={true}
                                    dataSource={costEstimates?.filter(
                                        p => p.PostExhibitStatus?.PostExhibitStatusId === getPostExhibitStatusIdByName('Draft')
                                    )}
                                    onCellPrepared={CustomPreparedHeaderCell}
                                    showBorders={true}
                                    showRowLines={true}
                                    rowAlternationEnabled={true}
                                >
                                    <FilterRow visible={true} />
                                    <KeyboardNavigation enabled={true} />
                                    <Paging defaultPageSize={10} />
                                    <Pager
                                        showPageSizeSelector={true}
                                        allowedPageSizes={allowedPageSizes}
                                    />
                                    <SearchPanel
                                        visible={true}
                                        highlightCaseSensitive={true}
                                    />
                                    <Toolbar>
                                        <DataGridItem
                                            name="searchPanel"
                                            locateInMenu="auto"
                                        />
                                    </Toolbar>
                                    <Column
                                        dataField="PostExhibitId"
                                        caption="PostExhibit ID"
                                        cellRender={(cellData) => (
                                            <a
                                                href="#"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    promoteToMain(cellData.value);
                                                }}
                                            >
                                                Promote To Main: {cellData.value}
                                            </a>
                                        )}
                                    />
                                    <Column dataField="Name" caption="Name" />
                                    <Column dataField="TaskOrderId" caption="TaskOrder ID" />
                                    <Column dataField="VendorName" caption="Vendor" />
                                    <Column dataField="ContractId" caption="Contract Number" />
                                    <Column dataField="RegionId" caption="Region" />
                                    <Column dataField="FiscalYear" caption="Fiscal Year" />
                                    <Column dataField="PostExhibitStatus.PostExhbitStatusId" caption="Status" />
                                    <Column dataField="StartDate" caption="Start Date" hidingPriority={2} />
                                    <Column dataField="EndDate" caption="End Date" hidingPriority={1} />
                                </DataGrid>
                            </Tab>
                        }
                    </TabbedItem>
                </Form>
            </ValidationGroup>
        </div>
    )
}