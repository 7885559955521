import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { LoadingIndicatorCircle } from "../components/Loading";
import GetFetch from "../hooks/GetFetch";
import CustomerForm from "./components/Form/CustomerForm";
import { useAuth } from "../hooks/useAuth";
import { GetDateToday_dashes_reverse } from "../util/GetDateToday";

const UpdateCustomer = () => {
    const { customerId } = useParams();
    const [data, setData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const { user } = useAuth();
    useEffect(() => {
        fetchCustomer(customerId);
    }, []);
    const fetchCustomer = async (id) => {
        setIsLoading(true);
        const { Success, Message, Errors } = await GetFetch(`/v1/Customer/GetCustomerById/${id}`);

        if (Success) {
            let copy = JSON.parse(JSON.stringify(Message));
            copy.UpdatedDate = GetDateToday_dashes_reverse();
            copy.UpdatedBy = user.email;
            setData(copy);
            // console.log("Message", Message)
        }
        else {
            setError(Errors[0].Message);
        }
        setIsLoading(false);
    }
    return (
        <>

            {isLoading && <LoadingIndicatorCircle message={`Loading Customer Account ${customerId}...`} />}
            {data &&
                <>
                    <div>
                        <span>Viewing Agency: <strong>{data.AgencyName}</strong></span>
                    </div>
                    <CustomerForm
                        data={data}
                        isLoading={isLoading}
                    />
                </>
            }
        </>
    )
}
export default UpdateCustomer;