import React, { useRef, useState, useCallback, useEffect } from "react";
import SelectBox from 'devextreme-react/select-box';
import DataGrid, {
    Column,
    KeyboardNavigation,
    FilterRow,
    Paging,
    Pager,
    SearchPanel,
    Toolbar,
    Lookup,
    Item,
    Selection,
    Summary,
    GroupItem,
    TotalItem
} from 'devextreme-react/data-grid';
import { useNavigate } from "react-router-dom";
import { ROUTE_CREATE_EDIT_POST } from "../../routes/Routes";
import { allowedPageSizes } from "../../components/GlobalDataGridConfigurations";
import { Button } from "devextreme-react";

export default function DataGridPost({ postExhibit, postExhibitFormData, feetypes, postStatuses, editing, postDataRef, isCostEstimate }) {
    const navigate = useNavigate();
    const [groupColumn, setGroupColumn] = useState('PostId');
    const [hiddenPosts, setHiddenPosts] = useState([]);
    const [isShowingHidden, setIsShowingHidden] = useState(false);
    const [filteredPosts, setFilteredPosts] = useState(postExhibitFormData?.Posts || []);

    const countLabel = { 'aria-label': 'Totals' };
    const groupingValues = [
        {
            value: 'PostId',
            text: 'Total By Post',
        },
        {
            value: 'Building.SequenceNumber',
            text: 'Total by Building',
        },
        {
            value: 'CLIN',
            text: 'Total by CLIN',
        },
        {
            value: 'CustomerName',
            text: 'Total by Customer',
        },
        {
            value: 'FeeTypeId',
            text: 'Total by FeeType',
        },
    ];

    const navigateToNewPostForm = () => {
        navigate(ROUTE_CREATE_EDIT_POST.withSlash, {
            state: {
                postExhibit: postExhibit,
                postExhibitFormData: postExhibitFormData,
                isNewPostExhibit: true,
                isCostEstimate: isCostEstimate
            },
        });
    }

    const navigateToEditPostForm = async (post) => {
        navigate(ROUTE_CREATE_EDIT_POST.withSlash, {
            state: {
                post: post,
                postExhibit: postExhibit,
                postExhibitFormData: postExhibitFormData,
                isCostEstimate: isCostEstimate
            }
        });
    }

    const toggleGroupColumn = useCallback((e) => {
        const newGrouping = e.value;

        postDataRef.current.instance.clearGrouping();
        if (newGrouping !== 'PostId')
            postDataRef.current.instance.columnOption(newGrouping, 'groupIndex', 0);

        setGroupColumn(newGrouping);
    }, []);

    const toggleHideSelected = () => {
        if (isShowingHidden) {
            setHiddenPosts([]);
            setIsShowingHidden(false);
        } else {
            const selectedPosts = postDataRef.current?.instance.getSelectedRowKeys();
            setHiddenPosts(selectedPosts);
            setIsShowingHidden(true);
        }
    };

    useEffect(() => {
        const hiddenPostIdsArray = hiddenPosts.map(post => Number(post.PostId));

        const updatedPosts = isShowingHidden
            ? postExhibitFormData?.Posts.filter(post => !hiddenPostIdsArray.includes(Number(post.PostId)))
            : postExhibitFormData?.Posts;

        setFilteredPosts(updatedPosts);
    }, [hiddenPosts, isShowingHidden, postExhibitFormData?.Posts]);

    return (
        <DataGrid
            id="postExhibit_postDataGrid"
            ref={postDataRef}
            columnAutoWidth={true}
            dataSource={filteredPosts}
            showBorders={true}
            showRowLines={true}
            rowAlternationEnabled={true}
            key="PostId">
            <FilterRow visible={true} />
            <KeyboardNavigation enabled={true} />
            <Paging defaultPageSize={10} />
            <Pager
                showPageSizeSelector={true}
                allowedPageSizes={allowedPageSizes}
            />
            <SearchPanel
                visible={true}
                highlightCaseSensitive={true}
            />
            <Selection mode={"multiple"} showCheckBoxesMode="always" selectAllMode="page" />
            <Toolbar>
                <Item location="before">
                    <Button
                        text={isShowingHidden ? "Show All" : "Hide Selected"}
                        onClick={toggleHideSelected}
                    />
                </Item>
                <Item location="before">
                    <SelectBox
                        width="225"
                        items={groupingValues}
                        displayExpr="text"
                        inputAttr={countLabel}
                        valueExpr="value"
                        value={groupColumn}
                        onValueChanged={toggleGroupColumn} />
                </Item>
                <Item
                    widget="dxButton"
                    location="after"
                    options={{
                        icon: 'add',
                        text: 'New Post',
                        onClick: () => {
                            navigateToNewPostForm();
                        }
                    }}
                    visible={editing}
                />
            </Toolbar>
            {editing ?
                (
                    <Column
                        dataField="PostId"
                        caption="Post ID"
                        cellRender={(cellData) => {
                            const post = postExhibitFormData.Posts.find(
                                p => p.PostId === cellData.value || p.TempPostId === cellData.value
                            );

                            return (
                                <a
                                    href="#"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        navigateToEditPostForm(post);
                                    }}
                                >
                                    Edit: {cellData.value}
                                </a>
                            );
                        }}
                    />
                ) :
                <Column
                    dataField="PostId"
                    caption="Post ID"
                />
            }
            <Column
                dataField="PostLocation.Name"
                caption="Post Location"
            />
            <Column
                dataField="CLIN"
                caption="CLIN"
            />
            <Column
                dataField="Building.RegionNumber"
                caption="Region"
            />
            <Column
                dataField="PostNumber"
                caption="Post Number"
            />
            <Column
                dataField="PostStatusId"
                caption="Status"
            >
                <Lookup
                    dataSource={postStatuses}
                    displayExpr='Name'
                    valueExpr='PostStatusId'
                />
            </Column>
            <Column
                dataField="FeeTypeId"
                caption="Fee Type"
            >
                <Lookup
                    dataSource={feetypes}
                    displayExpr='FeeTypeName'
                    valueExpr='FeeTypeId'
                />
            </Column>
            <Column
                dataField="Building.Name"
                caption="Building Name"
                hidingPriority={10}
            />
            <Column
                dataField="Building.SequenceNumber"
                caption="Building Number"
            />
            <Column
                dataField="CustomerName"
                caption="Customer Name"
                hidingPriority={9}
                calculateCellValue={(rowData) => {
                    if (rowData.PostAllocations) {
                        return rowData.PostAllocations[0]?.CustomerName;
                    }
                    // Display if at least one PostAllocation exists
                    return null;
                }}
            />
            <Column
                dataField="CustomerNumber"
                caption="Customer Number"
                hidingPriority={8}
                calculateCellValue={(rowData) => {
                    if (rowData.PostAllocations) {
                        return rowData.PostAllocations[0]?.CustomerNumber;
                    }
                    // Display if at least one PostAllocation exists
                    return null;
                }}
            />
            <Column
                dataField="Utilization"
                caption="Utilization Rate"
                hidingPriority={7}
            />
            <Column
                dataField="HourlyRate"
                caption="Hourly Rate"
            />
            <Column
                dataField="BuildingAddress.Address1"
                caption="Address"
                hidingPriority={6}
            />
            <Column
                dataField="BuildingAddress.CityName"
                caption="City"
                hidingPriority={5}
            />
            <Column
                dataField="BuildingAddress.State"
                caption="State"
                hidingPriority={4}
            />
            <Column
                dataField="BuildingAddress.ZipCode"
                caption="Zip Code"
                hidingPriority={3}
            />
            <Column
                dataField="StartDate"
                caption="Start Date"
                hidingPriority={2}
            />
            <Column
                dataField="EndDate"
                caption="End Date"
                hidingPriority={1}
            />
            <Column
                dataField="TotalServiceCosts" format="currency"
                caption="Total Costs"
                calculateCellValue={(rowData) => {
                    const baseCost = parseFloat(rowData.TotalServiceCosts);
                    if (!isNaN(baseCost)) {
                        return postExhibitFormData?.InflationRate ? baseCost * 1.1 : baseCost;
                    }
                    return null;
                }}
            />
            <Summary >
                <GroupItem
                    column="TotalServiceCosts"
                    summaryType="sum"
                    valueFormat="currency"
                    displayFormat="Subtotal: {0}"
                    showInGroupFooter={true}
                    calculateSummaryValue={(data) => {
                        const baseCost = parseFloat(data.TotalServiceCosts);
                        return !isNaN(baseCost)
                            ? (postExhibitFormData?.InflationRate ? baseCost * 1.1 : baseCost)
                            : null;
                    }}
                />
                <TotalItem
                    column="TotalServiceCosts"
                    summaryType="sum"
                    valueFormat="currency"
                    displayFormat="Total: {0}"
                    calculateSummaryValue={(data) =>{
                        const baseCost = parseFloat(data.TotalServiceCosts);
                        return !isNaN(baseCost)
                            ? (postExhibitFormData?.InflationRate ? baseCost * 1.1 : baseCost)
                            : null;
                    }}
                />
            </Summary>
        </DataGrid>
    )
}