import React, { useEffect, useState } from 'react';
import './css/Customers.scss';
import { ROUTE_CUSTOMERS } from '../routes/Customer/Routes';
import { Outlet } from 'react-router-dom';

const CustomersContainer = () => {
  let pageTitle = ROUTE_CUSTOMERS.title;

  useEffect(() => {
    const title = ROUTE_CUSTOMERS.title;
    document.title = title;
  }, []);
  return (
    <>
      <h1>{pageTitle}</h1>
      <Outlet />
    </>

  );
}

export default CustomersContainer;
