import {
    useEffect,
    useRef,
    useState
} from "react";
import { ValidationGroup } from "devextreme-react";
import { ToolbarForm } from "../components/toolbar-form/toolbar-form";
import OccupancyFormDetails from "./OccupancyFormDetails";
import PutPostPatchFetch from "../hooks/PutPostPatchFetch";
import { useParams } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";

const OccupancyForm = ({ data, isLoading = false }) => {
    // console.log("Data at OccupancyForm.:", data);
    const [editing, setEditing] = useState(false);
    const [formData, setFormData] = useState(data);
    const dataRef = useRef(null);
    const { id } = useParams();
    const { user } = useAuth();

    useEffect(() => {
        setFormData(data);
    }, [data]);
    const handleEditClick = () => {
        if (editing === false && formData) {
            dataRef.current = formData;
        } else {
            dataRef.current = undefined;
        }
        setEditing(!editing);
    };
    const onSaveClick = async ({ validationGroup }) => {
        // if (!validationGroup.validate().isValid) return;
        console.log("Saving Occupancy Agreement: ", formData);
        const putObject = {
            "OccupancyAgreementId": formData.OccupancyAgreementId,
            "BuildingByCustomerId": formData.BuildingByCustomer.BuildingByCustomerId,
            "CustomerAccountId": id,
            "EffectiveDate": formData.EffectiveDate,
            "ExpirationDate": formData.ExpirationDate,
            "CreatedDate": formData.CreatedDate,
            "CreatedBy": formData.CreatedBy,
            "UpdatedDate": formData.CreatedBy,
            "UpdatedBy": user.email,
            "OANumber": formData.OANumber,
            "LeaseNumber": formData.LeaseNumber,
            "MemberName": formData.MemberName,
            "TotalRentSqFt": formData.TotalRentSqFt,
            "JURentSqFt": formData.JURentSqFt,
            "RUFactor": formData.RUFactor,
            "Inactive": formData.Inactive,
            "BuildingAnsiRent": formData.BuildingAnsiRent,
        };
        const result = await PutPostPatchFetch('/v1/OccupancyAgreement', 'PUT', putObject);
        setEditing(false);

        console.log(result);
    };

const onCancelClick = () => {
    setFormData(dataRef.current);
    handleEditClick();
};

const updateField = (field, value) => {
    if (!formData)
        return;
    // console.log(formData);
    // console.log("field:",field);
    // console.log("value:",value);
    formData[field] = value;
    setFormData({ ...formData, ...{ [field]: value } });
    // console.log("full new object",formData);
};
return (
    <div className='occupancy-agreement-form'>
        <ValidationGroup>
            <ToolbarForm
                toggleEditing={handleEditClick}
                onSaveClick={onSaveClick}
                editing={editing}
                onCancelClick={onCancelClick}

            />
            <OccupancyFormDetails
                loading={isLoading}
                hasData={!formData}
                data={formData}
                editing={editing}
                updateField={updateField}
            // updateObject={updateObject}
            //   panelProps={{
            //     container: '.contact-form',
            //     position: { of: '.contact-form' },
            //   }}
            />
        </ValidationGroup>
    </div>
)
}

export default OccupancyForm;