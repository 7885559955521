import { useCallback, useEffect, useState } from "react";
import { ROUTE_OCCUPANCY_AGREEMENTS, ROUTE_OCCUPANCY_AGREEMENTS_LIST, ROUTE_OCCUPANCY_AGREEMENTS_UPDATE } from "../routes/Routes";
import GetFetch from "../hooks/GetFetch";
import { BaseSelectField } from "../components/BaseSelectField/BaseSelectField";
import { LoadingIndicatorCircle } from "../components/Loading";
import AddOccupancyAgreement from "./AddOccupancyAgreement";
import { DataGrid, Popup, Toast } from "devextreme-react";
import CustomPreparedHeaderCell from "../components/CustomPreparedHeaderCell";
import { Column, CustomRule, Editing, Form, FormItem, Item, KeyboardNavigation, Label, Lookup, Toolbar } from "devextreme-react/cjs/data-grid";
import PutPostPatchFetch from "../hooks/PutPostPatchFetch";
import { arrayIncludes } from "@mui/x-date-pickers/internals/utils/utils";
import { GetDateToday_dashes_reverse } from "../util/GetDateToday";
import { BaseButton } from "../components/BaseButton/BaseButton";
import { Link, Navigate, Outlet, useNavigate, useParams } from "react-router-dom";
import { GroupItem } from "devextreme-react/cjs/form";
import { useAuth } from "../hooks/useAuth";

const ListOccupancyAgreements = () => {
    const [occupancyAgreements, setOccupancyAgreements] = useState([]);
    const [buildings, setBuildings] = useState([]);
    const [loadingOccupancyAgreements, setLoadingOccupancyAgreements] = useState(false);
    const [occupancyAgreementSaved, setOccupancyAgreementSaved] = useState(false);
    const [saveError, setSaveError] = useState(false);
    const [hideTable, setHideTable] = useState(false);
    const [selectedOa, setSelectedOa] = useState(null);
    const [occupancyAgreementToAdd, setOccupancyAgreementToAdd] = useState(
        {
            "OccupancyAgreementId": 0,
            "BuildingByCustomerId": 0,
            "CustomerAccountId": 0,
            "EffectiveDate": null,
            "ExpirationDate": null,
            "CreatedDate": getCurrentDate(),
            "CreatedBy": null,
            "UpdatedDate": null,
            "UpdatedBy": null,
            "BuildingId": 0,
            "OANumber": null,
            "LeaseNumber": null,
            "MemberName": null,
            "TotalRentSqFt": 0,
            "JURentSqFt": 0,
            "RUFactor": 0,
            "Inactive": false,
            "BuildingAnsiRent": 0,
            "BuildingByCustomer": {
                "BuildingByCustomerId": 0,
                "CustomerId": 0,
                "BuildingId": 0,
                "IsInactive": false,
                "CreatedDate": getCurrentDate(), // Use the function
                "CreatedBy": "yourName", // Replace with actual value
                "UpdatedDate": getCurrentDate(), // Use the function
                "UpdatedBy": "yourName", // Replace with actual value
                "Building": null,
                "Customer": null,
                "OccupancyAgreement": "string" // Replace with actual value
            }
        }
    )
    const navigate = useNavigate();
    const { id, occupancyAgreementId } = useParams();
    const { user } = useAuth();
    useEffect(() => {
        if (id) {
            fetchOccupancyAgreementsByCustomerId(id);
        }
    }, [id]);
    useEffect(() => {
        if (occupancyAgreementId) {
            setHideTable(true);
        }
    }, [occupancyAgreementId]);

    const fetchOccupancyAgreementsByCustomerId = async (customerId) => {
        setOccupancyAgreements([]);
        setLoadingOccupancyAgreements(true);
        const { Success, Message, Errors } = await GetFetch('/v1/OccupancyAgreement/GetByOccupancyAgreementsByCustomerId/' + customerId);

        if (Success) {
            setOccupancyAgreements(Message);
            console.log("top level query", Message);
            let masterListBbc = [];
            Message.forEach(oa => {
                if (oa.BuildingByCustomer !== null) {
                    masterListBbc.push(oa.BuildingByCustomer);
                }
            });
            console.log("masterListBbc", masterListBbc);
            const uniqueBbcIds = new Set(masterListBbc.map(bbc => bbc.BuildingId));
            // console.log("uniqueBbcIds", uniqueBbcIds)
            const uniqueArr = Array.from(uniqueBbcIds).map(id => masterListBbc.find(item => item.BuildingId === id));
            console.log("unique buildings list", uniqueArr);
            setBuildings(uniqueArr);
        }
        else {
            setError(Errors[0].Message);
        }
        setLoadingOccupancyAgreements(false);
    }
    const onHiding = useCallback(() => {
        setOccupancyAgreementSaved(false);
        setSaveError(false);
    }, [occupancyAgreementSaved, setOccupancyAgreementSaved, saveError, setSaveError]);

    function getCurrentDate() {
        const date = new Date();
        return date.toISOString().split('T')[0]; // Format as YYYY-MM-DD
    }
    const handleEdit = (id) => {
        // alert("hgd" + occupancyAgreement.OccupancyAgreementId)
        navigate(`${id}/update`);

    }
    const renderEdit = (e) => {
        const occupancyAgreement = e.data;
        return (
            <>
                <BaseButton
                    label={"Edit"}
                    noStyle={true}
                    onClick={() => { handleEdit(occupancyAgreement.OccupancyAgreementId) }}
                    variant={"outlined"}

                />
            </>
        )
    }
    const unhideTableAndRemoveUpdateUrl = () => {
        navigate(-1);
        setHideTable(false);
    }
    const updateField = (field, value) => {
        // if(!formData) 
        //   return;
        // console.log(formData);
        console.log(field);
        console.log(value);
        occupancyAgreementToAdd[field] = value;
        setOccupancyAgreementToAdd({ ...occupancyAgreementToAdd, ...{ [field]: value } });
        console.log("occupancyAgreementToAdd:", occupancyAgreementToAdd);
    };
    return (
        <>
            {id && <>
                <Outlet />
                {hideTable ?
                    <>
                        <BaseButton
                            label={"Done"}
                            onClick={unhideTableAndRemoveUpdateUrl}
                            variant={"contained"}
                        />
                    </>
                    :
                    <>
                        {loadingOccupancyAgreements ? <LoadingIndicatorCircle message="Loading occupancy agreements..." /> :
                            <>
                                <Toast
                                    visible={occupancyAgreementSaved}
                                    message={"New Occupancy Agreement Successfully Saved..."}
                                    type={"success"}
                                    onHiding={onHiding}
                                    displayTime={600}
                                />
                                <Toast
                                    visible={saveError}
                                    message={"Couldn't save occupancy agreement, please try again later..."}
                                    type={"error"}
                                    onHiding={onHiding}
                                    displayTime={600}
                                />
                                <DataGrid
                                    columnAutoWidth={true}
                                    dataSource={occupancyAgreements}
                                    key={"OccupancyAgreementId"}
                                    showBorders={true}
                                    showColumnLines={true}
                                    showRowLines={true}
                                    rowAlternationEnabled={true}
                                    onCellPrepared={CustomPreparedHeaderCell}
                                    noDataText="This Customer does not have any Occupancy Agreements"
                                    onRowInserted={async (e) => {
                                        console.log(e);
                                        const { data } = e;
                                        console.log("SUBMITTED DATA:", data);
                                        occupancyAgreementToAdd
                                        const occupancyAgreement = {
                                            "OccupancyAgreementId": 0,
                                            "BuildingByCustomerId": occupancyAgreementToAdd.BuildingByCustomer.BuildingByCustomerId ?? 0,
                                            "CustomerAccountId": id,
                                            "EffectiveDate": occupancyAgreementToAdd.EffectiveDate ?? getCurrentDate(), // Use the function
                                            "ExpirationDate": occupancyAgreementToAdd.ExpirationDate || getCurrentDate(), // Use the function
                                            "CreatedDate": getCurrentDate(), // Use the function
                                            "CreatedBy": user.email, // Replace with actual value
                                            "UpdatedDate": null, // Use the function
                                            "UpdatedBy": "yourName", // Replace with actual value
                                            "BuildingId": 0,
                                            "OANumber": data.OANumber || "string",
                                            "LeaseNumber": data.LeaseNumber || "string",
                                            "MemberName": data.MemberName || "string",
                                            "TotalRentSqFt": data.TotalRentSqFt || 0,
                                            "JURentSqFt": data.JURentSqFt || 0,
                                            "RUFactor": data.RUFactor || 0,
                                            "Inactive": false,
                                            "BuildingAnsiRent": 0,
                                            "BuildingByCustomer": {
                                                "BuildingByCustomerId": 0,
                                                "CustomerId": 0,
                                                "BuildingId": 0,
                                                "IsInactive": false,
                                                "CreatedDate": getCurrentDate(), // Use the function
                                                "CreatedBy": "yourName", // Replace with actual value
                                                "UpdatedDate": getCurrentDate(), // Use the function
                                                "UpdatedBy": "yourName", // Replace with actual value
                                                "Building": null,
                                                "Customer": null,
                                                "OccupancyAgreement": "string" // Replace with actual value
                                            }
                                        };

                                        const result = await PutPostPatchFetch('/v1/OccupancyAgreement', 'POST', occupancyAgreement);
                                        const { Success, Message, Errors } = result;
                                        setOccupancyAgreementSaved(Success);
                                        setSaveError(Errors.length > 0)

                                    }}
                                    width={1200}
                                >
                                    <KeyboardNavigation enabled={true} />
                                    <Column alignment="center" dataField="Number" name="Edit" caption="" width={80} cellRender={(e) => renderEdit(e)} />
                                    <Column dataField="OccupancyAgreementId" caption="Id" allowEditing={false} />
                                    <Column dataType="string" dataField="OANumber" caption="OA Number" />
                                    <Column dataField="BuildingByCustomer.BuildingByCustomerId" caption="Building" >
                                        <Lookup dataSource={buildings} displayExpr="Building.SequenceNumber" valueExpr="BuildingByCustomerId" />
                                    </Column>
                                    {/* <Column dataField="EffectiveDate" caption="Effective Date" width={200} /> */}
                                    <Column
                                        dataType="date"
                                        dataField="EffectiveDate"
                                        format="shortDate"
                                        caption="Effective Date"
                                        editorOptions={{
                                            useMaskBehavior: true,
                                            placeholder: "MM/DD/YYYY"
                                        }}
                                    >
                                        <CustomRule
                                            type="custom"
                                            message="Date must be before the Expiration Date"
                                            validationCallback={
                                                (e) => {
                                                    const effective = e.data?.EffectiveDate
                                                    const expiration = e.data?.ExpirationDate
                                                    return (!effective || !expiration || effective < expiration)
                                                }
                                            }
                                        />
                                    </Column>
                                    <Column
                                        dataType="date"
                                        dataField="ExpirationDate"
                                        caption="Expiration Date"
                                        format="shortDate"
                                        editorOptions={{
                                            useMaskBehavior: true,
                                            placeholder: "MM/DD/YYYY"
                                        }}
                                    >
                                        <CustomRule
                                            type="custom"
                                            message="Date must be after the Occupancy Agreement Effective Date"
                                            validationCallback={
                                                (e) => {
                                                    const effective = e.data?.EffectiveDate
                                                    const expiration = e.data?.ExpirationDate
                                                    return (!effective || !expiration || effective < expiration)
                                                }
                                            }
                                        />
                                    </Column>
                                    <Column
                                        dataType="date"
                                        dataField="CreatedDate"
                                        caption="Created Date"
                                        format="shortDate"
                                        allowEditing={false} />
                                    <Column
                                        dataType="string"
                                        dataField="CreatedBy"
                                        caption="Created By"
                                        allowEditing={false} />
                                    <Column
                                        dataType="string"
                                        dataField="LeaseNumber"
                                        caption="Lease Number"
                                    />
                                    <Column
                                        dataType="string"
                                        dataField="MemberName"
                                        caption="Member Name"
                                    />
                                    <Column
                                        dataType="number"
                                        dataField="TotalRentSqFt"
                                        caption="Total Rent Square Foot"
                                    />
                                    <Column
                                        dataType="number"
                                        dataField="RUFactor"
                                        caption="RU Factor"
                                    />
                                    <Column
                                        dataType="number"
                                        dataField="JURentSqFt"
                                        caption="JURentSqFt"
                                    />
                                    <Editing mode="popup" allowAdding={true}>
                                        <Form
                                            labelLocation="top"
                                        >
                                            <Item caption='General Information' itemType='group'>
                                                <Item
                                                    dataType="string"
                                                    dataField="OANumber"
                                                    caption="OA Number"
                                                    labelMode='static'
                                                    elementAttr={{ class: 'form-editor' }}
                                                    onValueChange={() => updateField('OANumber')}
                                                />
                                                <Item
                                                    dataField="BuildingByCustomer.BuildingByCustomerId"
                                                    caption="Building"
                                                    labelMode='static'
                                                    elementAttr={{ class: 'form-editor' }}
                                                    onValueChange={() => updateField('BuildingByCustomer.BuildingByCustomerId')}
                                                />
                                                <Item
                                                    dataType="date"
                                                    dataField="EffectiveDate"
                                                    format="shortDate"
                                                    caption="Effective Date"
                                                    editorOptions={{ 
                                                        useMaskBehavior: true, 
                                                        placeholder: "MM/DD/YYYY" 
                                                    }}
                                                    labelMode='static'
                                                    elementAttr={{ class: 'form-editor' }}
                                                    onValueChange={() => updateField('EffectiveDate')}
                                                >
                                                    <CustomRule
                                                        type="custom"
                                                        message="Date must be before the Expiration Date"
                                                        validationCallback={
                                                            (e) => {
                                                                const effective = e.data?.EffectiveDate
                                                                const expiration = e.data?.ExpirationDate
                                                                return (!effective || !expiration || effective < expiration)
                                                            }
                                                        }
                                                    />
                                                </Item>
                                                <Item
                                                    dataType="date"
                                                    dataField="ExpirationDate"
                                                    caption="Expiration Date"
                                                    format="shortDate"
                                                    editorOptions={{ useMaskBehavior: true, placeholder: "MM/DD/YYYY" }}
                                                    labelMode='static'
                                                    elementAttr={{ class: 'form-editor' }}
                                                    onValueChange={() => updateField('ExpirationDate')}
                                                />
                                                <Item
                                                    dataType="string"
                                                    dataField="MemberName"
                                                    caption="Member Name"
                                                    labelMode='static'
                                                    elementAttr={{ class: 'form-editor' }}
                                                    onValueChange={() => updateField('MemberName')}
                                                />
                                            </Item>
                                            <Item caption='Financial Information' itemType='group'>
                                                <Item
                                                    dataType="string"
                                                    dataField="LeaseNumber"
                                                    caption="Lease Number"
                                                    labelMode='static'
                                                    elementAttr={{ class: 'form-editor' }}
                                                    onValueChange={() => updateField('LeaseNumber')}
                                                />
                                                <Item
                                                    dataType="number"
                                                    dataField="TotalRentSqFt"
                                                    caption="Total Rent Square Foot"
                                                    labelMode='static'
                                                    elementAttr={{ class: 'form-editor' }}
                                                    onValueChange={() => updateField('TotalRentSqFt')}
                                                />
                                                <Item
                                                    dataType="number"
                                                    dataField="RUFactor"
                                                    caption="RU Factor"
                                                    labelMode='static'
                                                    elementAttr={{ class: 'form-editor' }}
                                                    onValueChange={() => updateField('RUFactor')}
                                                />
                                                <Item
                                                    dataType="number"
                                                    dataField="JURentSqFt"
                                                    caption="JURentSqFt"
                                                    labelMode='static'
                                                    elementAttr={{ class: 'form-editor' }}
                                                    onValueChange={() => updateField('JURentSqFt')}
                                                />
                                            </Item>
                                        </Form>
                                    </Editing>
                                </DataGrid>
                            </>
                        }
                    </>
                }

            </>}
        </>
    )
}
export default ListOccupancyAgreements;