import { useState } from "react";
import CustomerForm from "./components/Form/CustomerForm";
import { GetDateToday_dashes_reverse } from "../util/GetDateToday";
import { useAuth } from "../hooks/useAuth";

const CreateCustomer = () => {
    const { user } = useAuth();
    const [data, setData] = useState({
        "CustomerId": 0,
        "AgencyName": "",
        "AgencyBureauName": "",
        "AgencyBureauShortName": "",
        "AgencyBureauCode": undefined,
        "BilledAgencyBureauCode": "",
        "ActualAgencyBureauCode": "",
        "AgencyLocationCode": "",
        "TreasuryAccSymbol": "",
        "BusinessEventTypeCode": "",
        "CustomerPOCFirstName": "",
        "CustomerPOCLastName": "",
        "POCsToRecieveSummary": true,
        "SendIPAC": true,
        "FundingDocumentNumber": "",
        "BureauAddress": {
          "Id": 0,
          "Address1": "",
          "Address2": "",
          "CityName": "",
          "State": "",
          "ZipCode": ""
        },
        "CreatedBy": user.email,
        "CreatedDate": GetDateToday_dashes_reverse(),
        "UpdatedBy": "",
        "UpdatedDate": "2025-02-08"
      
    });
    const [isLoading, setIsLoading] = useState(false);
    // const [error, setError] = useState(null);

    return (
        <>
            <h4>Add a Customer</h4>
            <CustomerForm
                data={data}
                isLoading={isLoading}
                create={true}
            />
        </>
    )
}
export default CreateCustomer;