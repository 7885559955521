import React, { useEffect, useState } from "react";
import { useAuth } from "../hooks/useAuth";
import { Button } from "devextreme-react";
import { LoadingPanel } from "../components/LoadingPanel";
import { useNavigate } from "react-router-dom";
import { ROUTE_POST_EXHIBITS_LIST_PAGE, ROUTE_CREATE_EDIT_POST_EXHIBIT, ROUTE_WELCOME } from "../routes/Routes";
import "./css/PostExhibits.scss";
import DataGridPostExhibits from "./components/DataGridPostExhibits";
import { fetchPostExhibits } from "./PostExhibitsService";
import IsNullOrEmpty from "../components/IsNullOrEmpty";
import { getPostExhibitStatusIdByName } from "../Enums/postExhibitStatuses";

export default function PostExhibitsList() {
    const [loading, setLoading] = useState(false);
    const [postExhibits, setPostExhibits] = useState(null);
    const [error, setError] = useState();
    const navigate = useNavigate();
    const { title } = ROUTE_POST_EXHIBITS_LIST_PAGE;

    useEffect(() => {
        loadPostExhibits();
    }, []);

    const loadPostExhibits = async () => {
        setLoading(true);
        try {
            const draftStatusId = getPostExhibitStatusIdByName('Draft');
            const data = await fetchPostExhibits();
            setPostExhibits(
                data.filter(p => 
                    p.PostExhibitStatus?.PostExhibitStatusId !== draftStatusId || 
                    IsNullOrEmpty(p.ClonedPostExhibitId)
                )
            );
        } catch (error) {
            setError(error.message || "Error loading Post Exhibits");
        }
        setLoading(false);
    }

    return (
        <>
            <h1>{title}</h1>
            <Button
                stylingMode="contained"
                text="Add a New Post Exhibit"
                width={220}
                onClick={() => navigate(ROUTE_CREATE_EDIT_POST_EXHIBIT.withSlash)}
                id="add_new_postexhibit_button_029"
            />
            {error && <p>"Something went wrong.</p>}
            {loading && !postExhibits && <LoadingPanel location={{ of: '#postExhibit-list' }} message="Loading Post Exhibits List..." />}
            {postExhibits &&
                <div id='postExhibit-list'>
                    <DataGridPostExhibits
                        dataSource={postExhibits}
                        isCostEstimate={false}
                    />
                </div>
            }
        </>
    )
}