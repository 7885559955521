import {
    createBrowserRouter,
    defer,
} from "react-router-dom";
import Welcome from '../WelcomePage/Welcome/Welcome';
import Buildings from '../BuildingsPage/Buildings';
import BuildingsUpdate from "../BuildingsPage/BuildingsUpdate";
import CostEstimates from '../FyCostEstimates/CostEstimates';
import HelpPage from '../Help/HelpPage';
import TotalBuildingCostReport from '../Reports/TotalBuildingCostReport';
import CostEstimatesReport from '../Reports/CostEstimateReport/CostEstimateReport';
import UsersPage from '../Users/UsersPage';
import LoginCognito from '../Login/LoginCognito';
import LoginOkta from "../Login/LoginOkta";
import NavigationMenu from "../NavigationMenu/NavigationMenu";
import ErrorPage from "./ErrorPage";
import { AuthLayout } from "./AuthLayout";
import RMS from "./RMS";
import AddNewUser from "../Users/AddUser";
import UpdateUser from "../Users/UpdateUser";
import RolesPage, { RoleLoader } from "../Roles/RolesPage";
import ActiveSessionsPage, { ActiveSessionsLoader } from "../Sessions/ActiveSessionsPage";
import SignUp from "../SignUp/SignUp"
import { UserLoader } from "../Users/UsersPageComponents";
import AddSecurityCost from "../FyCostEstimates/AddSecurityCost/AddSecurityCost";
import AuditReport from "../Reports/AuditReport/AuditReport";
import ConcurrentSession from "../Sessions/ConcurrentSession";
import UserReport from "../Reports/UserReport/UserReport";
import ContractPage from "../ContractComponents/ContractPage";
import AddAContractPage from "../ContractComponents/AddAContractPage";
import ContractAdd from "../ContractComponents/ContractAdd";
import ContractUpdate from "../ContractComponents/ContractUpdate";
import ContractCostEstimates from "../ContractComponents/ContractCostEstimates";
import { ValidateRoute } from "./ValidRoute";
import BillingAdjustments from "../BillingAdjustmentsPage/BillingAdjustments";
import PSO_ContractCostSummaryPage from "../ContractComponents/PSO_ContractCostSummaryPage/PSO_ContractCostSummaryPage";
import PSO_Contract from "../ContractComponents/PSO_Contract/PSO_Contract";
import AddAdjustment from "../BillingAdjustmentsPage/AddAdjustment";
/* import EditAdjustment from "../BillingAdjustmentsPage/EditAdjustment"; */
import CRMA_UploadPage from "../CRMA/CRMA_Upload/CRMA_Upload";
import CRMA_DownloadPage from "../CRMA/CRMA_Download/CRMA_Download";
import CRMA_ReportData from "../CRMA/CRMA_ReportData/CRMA_ReportData";
/* ------ CRMA Bill History Generator ------  */
import CRMA_HistoryBillGeneration from "../CRMA_HistoryBillGeneration/CRMA_HistoryBillGeneration";
/* ------ (end) CRMA Bill History Generator ------  */

/* ------ Region and Building Data Routes ------ */
import AdjustmentsReport from "../AdjustmentsReportPage/AdjustmentsReport";
import EditAdjustment from "../BillingAdjustmentsPage/EditAdjustment";
import BuildingSpecificCostEstimate from "../FyCostEstimates/SpecificCostEstimate/BuildingSpecificCostEstimate";
import AgencySpecificCostEstimate from "../FyCostEstimates/SpecificCostEstimate/AgencySpecificCostEstimate";
import WorkflowTaskList from "../WorkflowTasks/WorkflowTaskList";
import SpecificCostEstimateSummary from "../FyCostEstimates/SpecificCostEstimateSummary/SpecificCostEstimateSummary";
import PostExhibitsList from "../PostExhibitPage/PostExhibitsList";
import PostExhibitForm from "../PostExhibitPage/components/PostExhibitForm";
import PostForm from "../PostExhibitPage/components/PostForm";
import OccupancyAgreementPage from "../OccupancyAgreement/OccupancyAgreementPage";
import ListOccupancyAgreements from "../OccupancyAgreement/ListOccupancyAgreements";
import UpdateOccupancyAgreement from "../OccupancyAgreement/UpdateOccupancyAgreement";
import { customerRoutes } from "./Customer/Routes";

/* ------ Building Data Routes ------ */
export const ROUTE_REGION_AND_BUILDING_DATA = {
    plain: 'Region-and-Building-Data',
    withSlash: '/Region-and-Building-Data',
    title: 'Building Data',
    module: 'ROUTE_REGION_AND_BUILDING_DATA'
};
export const ROUTE_EDIT_BUILDING = {
    plain: 'Edit-Building',
    withSlash: '/Edit-Building',
    title: 'Edit a Building',
    module: 'ROUTE_REGION_AND_BUILDING_DATA',
    edit: true
}
/* ------ (end) Building Data Routes ------ */


/* ------ FY Cost Estimate Routes ------ */
export const ROUTE_FY_COST_ESTIMATE = {
    plain: 'FY-Cost-Estimate',
    withSlash: '/FY-Cost-Estimate',
    title: 'FY Cost Estimate',
    module: 'ROUTE_FY_COST_ESTIMATE'
};
export const ROUTE_ADD_SECURITY_COST = {
    plain: 'Add-Security-Cost',
    withSlash: '/Add-Security-Cost',
    title: 'Add a Security Cost',
    module: 'ROUTE_FY_COST_ESTIMATE',
    create: true
};
export const ROUTE_BUILDING_SPECIFIC_COST_ESTIMATE = {
    plain: 'Building-Specific-Cost-Estimate',
    withSlash: '/Building-Specific-Cost-Estimate',
    title: 'FY Cost Estimate Building Specific',
    module: 'ROUTE_BUILDING_SPECIFIC_ESTIMATE_REPORT'
};
export const ROUTE_AGENCY_SPECIFIC_COST_ESTIMATE = {
    plain: 'Agency-Specific-Cost-Estimate',
    withSlash: '/Agency-Specific-Cost-Estimate',
    title: 'FY Cost Estimate Agency Specific',
    module: 'ROUTE_AGENCY_SPECIFIC_ESTIMATE_REPORT'
};
export const ROUTE_SPECIFIC_COST_ESTIMATE_SUMMARY = {
    plain: 'Specific-Cost-Estimate-Summary',
    withSlash: '/Specific-Cost-Estimate-Summary',
    title: 'Specific Cost Estimate Summary',
    module: 'ROUTE_SPECIFIC_ESTIMATE_SUMMARY_REPORT'
};
/* ------ (end) FY Cost Estimate Routes ------ */

/* ------ Users Routes ------ */
export const ROUTE_USERS = {
    plain: 'Users',
    withSlash: '/Users',
    title: 'User Data',
    module: 'ROUTE_USERS'
};
export const ROUTE_ADD_USER = {
    plain: 'Add-User',
    withSlash: '/Add-User',
    title: 'Add a New User',
    module: 'ROUTE_USERS',
    create: true
};
export const ROUTE_EDIT_USER = {
    plain: 'Edit-User',
    withSlash: '/Edit-User',
    title: 'Edit an Existing User',
    module: 'ROUTE_USERS',
    edit: true
}
/* ------ (end) Users Routes ------ */

/* ------ Contract Routes ------ */
export const ROUTE_CONTRACTS_PAGE = {
    plain: 'Contracts',
    withSlash: '/Contracts',
    title: 'Contract Data',
    module: 'ROUTE_CONTRACTS_PAGE'
};
export const ROUTE_CREATE_A_NEW_CONTRACT_RECORD = {
    plain: 'ContractAdd',
    withSlash: '/ContractAdd',
    title: 'Contract Add',
    module: 'ROUTE_CONTRACTS_PAGE',
    create: true
};
export const ROUTE_EDIT_A_CONTRACT_RECORD = {
    plain: 'Edit-A-Contract-Record',
    withSlash: '/Edit-A-Contract-Record',
    title: 'Edit A Contract Record',
    module: 'ROUTE_CONTRACTS_PAGE',
    create: true
};
export const ROUTE_CONTRACT_UPDATE = {
    plain: 'ContractUpdate',
    withSlash: '/ContractUpdate',
    title: 'Contract Update',
    module: 'ROUTE_CONTRACTS_PAGE',
    create: true
};

/**
 * @todo TechDebt: Refactor ROUTE_PSO_CONTRACT_SUMMARY_PAGE nomenclature
 * to match the title of "Contract Summaries" in all React code
 * and in SystemModule in the database.
 */
export const ROUTE_PSO_CONTRACT_SUMMARY_PAGE = {
    plain: 'PSO-Contract-Summary-Page',
    withSlash: '/PSO-Contract-Summary-Page',
    title: 'Contract Summaries',
    module: 'ROUTE_PSO_CONTRACT_SUMMARY_PAGE'
};
/* ------ (end) Contract Routes ------ */

/* ------ Post Exhibit Routes ------ */
export const ROUTE_POST_EXHIBITS_LIST_PAGE = {
    plain: 'Post-Exhibits-List',
    withSlash: '/Post-Exhibits-List',
    title: 'Post Exhibit Data',
    module: 'ROUTE_POST_EXHIBITS_PAGE'
};
export const ROUTE_CREATE_EDIT_POST_EXHIBIT = {
    plain: 'Create-Edit-Post-Exhibit',
    withSlash: '/Create-Edit-Post-Exhibit',
    title: 'Post Exhibit Details',
    module: 'ROUTE_POST_EXHIBITS_PAGE'
};
export const ROUTE_CREATE_EDIT_POST = {
    plain: 'Create-New-Post',
    withSlash: '/Create-New-Post',
    title: 'Post',
    module: 'ROUTE_POST_EXHIBITS_PAGE'
};
export const ROUTE_EDIT_POST = {
    plain: 'Edit-Post',
    withSlash: '/Edit-Post',
    title: 'Edit Post',
    module: 'ROUTE_POST_EXHIBITS_PAGE'
};
/* ------ (end) Post Exhibit Routes ------ */

/* ------ CRMA ------ */
export const ROUTE_CRMA_UPLOAD = {
    plain: 'CRMA-Upload',
    withSlash: '/CRMA-Upload',
    title: 'CRMA Upload',
    module: 'ROUTE_CRMA_UPLOAD',
    create: true
};
export const ROUTE_CRMA_DOWNLOAD = {
    plain: 'CRMA-Download',
    withSlash: '/CRMA-Download',
    title: 'CRMA Download',
    module: 'ROUTE_CRMA_DOWNLOAD',
    create: true
};
export const ROUTE_CRMA_REPORT_DATA = {
    plain: 'CRMA-Report-Data',
    withSlash: '/CRMA-Report-Data',
    title: 'CRMA Report Data',
    module: 'ROUTE_CRMA_REPORT_DATA'
};
/* ------ (end) CRMA ------ */

/* ------ CRMA History Generations ------ */
export const ROUTE_CRMA_HISTORY_GENERATED_BILL_BFC = {
    plain: 'CRMA-History-Generated-Bill-BFC',
    withSlash: '/CRMA-History-Generated-Bill-BFC',
    title: 'CRMA History Generated Bill BFC',
    module: 'ROUTE_CRMA_HISTORY_GENERATED_BILL_BFC',
    routeId: 0
};
export const ROUTE_CRMA_HISTORY_GENERATED_BILL_GSA = {
    plain: 'CRMA-History-Generated-Bill-GSA',
    withSlash: '/CRMA-History-Generated-Bill-GSA',
    title: 'CRMA History Generated Bill GSA',
    module: 'ROUTE_CRMA_HISTORY_GENERATED_BILL_GSA',
    routeId: 1
};
export const ROUTE_CRMA_HISTORY_GENERATED_BILL_REGION = {
    plain: 'CRMA-History-Generated-Bill-Region',
    withSlash: '/CRMA-History-Generated-Bill-Region',
    title: 'CRMA History Generated Bill Region',
    module: 'ROUTE_CRMA_HISTORY_GENERATED_BILL_REGION',
    routeId: 2
};
/* ------ (end) CRMA History Generations ------ */

export const ROUTE_VIEW_SINGLE_PSO_CONTRACT = {
    plain: 'Single-PSO-Contract',
    withSlash: '/Single-PSO-Contract',
    title: 'PSO Contract Summary',
    module: 'ROUTE_VIEW_SINGLE_PSO_CONTRACT'
};
export const ROUTE_CONTRACT_COST_ESTIMATES = {
    plain: 'Contract-Cost-Estimates',
    withSlash: '/Contract-Cost-Estimates',
    title: 'PSO Cost Estimates',
    module: 'ROUTE_CONTRACT_COST_ESTIMATES'
};
/* ------ (end) Contract Routes ------ */

/* ------ Billing and Adjustment Routes ------ */
export const ROUTE_BILLING_ADJUSTMENTS = {
    plain: 'Billing-and-Adjustments',
    withSlash: '/Billing-and-Adjustments',
    title: 'Billing & Adjustments',
    module: 'ROUTE_BILLING_ADJUSTMENTS'
};
export const ROUTE_ADD_ADJUSTMENTS = {
    plain: 'Add-Adjustment',
    withSlash: '/Add-Adjustment',
    title: 'Add an Adjustment',
    module: 'ROUTE_BILLING_ADJUSTMENTS',
    create: true
};
export const ROUTE_EDIT_ADJUSTMENTS = {
    plain: 'Edit-Adjustment',
    withSlash: '/Edit-Adjustment',
    title: 'Edit an Adjustment',
    module: 'ROUTE_BILLING_ADJUSTMENTS',
    edit: true
};
export const ROUTE_ADJUSTMENTS_REPORT = {
    plain: 'Adjustments-Report',
    withSlash: '/Adjustments-Report',
    title: 'Adjustments Report',
    module: 'ROUTE_BILLING_ADJUSTMENTS'
};
/* ------ (end) Billing and Adjustment Routes ------ */

export const ROUTE_WORKFLOW_TASKS = {
    plain: 'Workflow-Tasks',
    withSlash: '/Workflow-Tasks',
    title: 'Workflow Tasks',
    module: 'ROUTE_WORKFLOW_TASKS'
};
export const ROUTE_WELCOME = {
    plain: '',
    withSlash: '/',
    title: 'Home',
    module: 'ROUTE_WELCOME'
};
export const ROUTE_COST_ESTIMATE_REPORT = {
    plain: 'Changes-of-Fiscal-Year-Cost-Estimates-Report',
    withSlash: '/Changes-of-Fiscal-Year-Cost-Estimates-Report',
    title: 'Changes of Fiscal Year Cost Estimates Report',
    module: 'ROUTE_COST_ESTIMATE_REPORT'
};
export const ROUTE_BUILDING_SPECIFIC_ESTIMATE_REPORT = {
    plain: 'Building-Specific-Cost-Report',
    withSlash: '/Building-Specific-Cost-Report',
    title: 'Building-Specific Cost Report',
    module: 'ROUTE_BUILDING_SPECIFIC_ESTIMATE_REPORT'
};
export const ROUTE_AUDIT_REPORT = {
    plain: 'Audit-Report',
    withSlash: '/Audit-Report',
    title: 'Audit Report',
    module: 'ROUTE_AUDIT_REPORT'
};
export const ROUTE_USER_REPORT = {
    plain: 'User-Report',
    withSlash: '/User-Report',
    title: 'User Report',
    module: 'ROUTE_USER_REPORT'
};
export const ROUTE_ROLES = {
    plain: 'Roles',
    withSlash: '/Roles',
    title: 'User Role Data',
    module: 'ROUTE_ROLES'
};
export const ROUTE_ACTIVE_SESSIONS = {
    plain: 'ActiveSessions',
    withSlash: '/ActiveSessions',
    title: 'Active User Sessions',
    module: 'ROUTE_ACTIVE_SESSIONS'
};
export const ROUTE_HELP = {
    plain: 'Help',
    withSlash: '/Help',
    title: 'Help',
    module: 'ROUTE_HELP'
};
export const ROUTE_LOGIN_OKTA = {
    plain: 'RMSLogin',
    withSlash: '/RMSLogin',
    title: 'Login Okta',
    module: 'ROUTE_LOGIN_OKTA'
};
export const ROUTE_LOGIN_COGNITO = {
    plain: 'LoginCognito',
    withSlash: '/LoginCognito',
    title: 'Login Cognito',
    module: 'ROUTE_LOGIN_COGNITO'
};
export const ROUTE_CONCURRENT_SESSION = {
    plain: 'Concurrent-Session',
    withSlash: '/Concurrent-Session',
    title: 'Concurrent Session',
    module: 'ROUTE_CONCURRENT_SESSION'
};
export const ROUTE_SIGN_UP = {
    plain: 'Sign-Up',
    withSlash: '/Sign-Up',
    title: 'Sign Up',
    module: 'ROUTE_SIGN_UP'
};
export const ROUTE_RMS = {
    plain: 'RMS',
    withSlash: '/RMS',
    title: 'RMS',
    module: 'ROUTE_RMS'
};
export const ROUTE_OCCUPANCY_AGREEMENTS = {
    plain: 'Occupancy-Agreements',
    withSlash: '/Occupancy-Agreements',
    title: 'Occupancy Agreements',
    module: 'ROUTE_OCCUPANCY_AGREEMENTS'
};
export const ROUTE_OCCUPANCY_AGREEMENTS_UPDATE = {
    plain: ':occupancyAgreementId/update',
    withSlash: '/:occupancyAgreementId/update',
    title: 'Update Occupancy Agreement',
    module: 'ROUTE_OCCUPANCY_AGREEMENTS'
};
export const ROUTE_OCCUPANCY_AGREEMENTS_LIST = {
    plain: ':id/list',
    withSlash: '/:id/list',
    title: 'Occupancy Agreements',
    module: 'ROUTE_OCCUPANCY_AGREEMENTS'
};

export const router = createBrowserRouter([
    {
        // If you need to access the useAuth.js hook with User data at anytime,
        // your route object MUST be a child of the AuthLayout.js route (line 35, children array)
        element: <AuthLayout />,
        errorElement: <ErrorPage />,
        children: [
            {
                path: ROUTE_LOGIN_COGNITO.plain,
                element: <LoginCognito />,
            },
            {
                path: ROUTE_LOGIN_OKTA.plain,
                element: <LoginOkta />,
            },
            {
                path: ROUTE_RMS.plain,
                element: <RMS />,
            },
            {
                path: ROUTE_CONCURRENT_SESSION.plain,
                element: <ConcurrentSession />,
            },
            {
                // Note: NavigationMenu holds our authentication ability, using RequireValidation.js Wrapper.
                // Any route object within NavigationMenu's children array will be required to have authenticated to view.
                path: ROUTE_WELCOME.withSlash,
                element: <NavigationMenu />,
                // errorElement: <RMS />, /* removed this due to our errors not being advertised, Access denied is its own route now, and errors get propogated up the error tree, see line 100 */
                children: [
                    {
                        index: true,
                        element: <Welcome />,
                    },
                    {
                        path: ROUTE_CONTRACTS_PAGE.plain,
                        element: <ValidateRoute route={ROUTE_CONTRACTS_PAGE}><ContractPage /></ValidateRoute>,
                    },
                    {
                        path: ROUTE_CREATE_A_NEW_CONTRACT_RECORD.plain,
                        element: <ValidateRoute route={ROUTE_CREATE_A_NEW_CONTRACT_RECORD}><ContractAdd /></ValidateRoute>,
                    },
/*                     {
                        path: ROUTE_EDIT_A_CONTRACT_RECORD.plain,
                        element: <ValidateRoute route={ROUTE_EDIT_A_CONTRACT_RECORD}><ContractEdit /></ValidateRoute>,
                    }, */
                    {
                        path: ROUTE_CONTRACT_UPDATE.plain,
                        element: <ValidateRoute route={ROUTE_CONTRACT_UPDATE}><ContractUpdate /></ValidateRoute>,
                    },
                    {
                        path: ROUTE_CONTRACT_COST_ESTIMATES.plain,
                        element: <ValidateRoute route={ROUTE_CONTRACTS_PAGE}><ContractCostEstimates /></ValidateRoute>,
                    },
                    {
                        path: ROUTE_VIEW_SINGLE_PSO_CONTRACT.plain,
                        element: <PSO_Contract />
                    },
                    {
                        path: ROUTE_PSO_CONTRACT_SUMMARY_PAGE.plain,
                        element: <ValidateRoute route={ROUTE_PSO_CONTRACT_SUMMARY_PAGE}><PSO_ContractCostSummaryPage /></ValidateRoute>
                    },
                    {
                        path: ROUTE_POST_EXHIBITS_LIST_PAGE.plain,
                        element: <ValidateRoute route={ROUTE_POST_EXHIBITS_LIST_PAGE}><PostExhibitsList /></ValidateRoute>
                    },
                    {
                        path: ROUTE_CREATE_EDIT_POST_EXHIBIT.plain,
                        element: <ValidateRoute route={ROUTE_CREATE_EDIT_POST_EXHIBIT}><PostExhibitForm /></ValidateRoute>
                    },
                    {
                        path: ROUTE_CREATE_EDIT_POST.plain,
                        element: <ValidateRoute route={ROUTE_CREATE_EDIT_POST}><PostForm /></ValidateRoute>
                    },
                    {
                        path: ROUTE_REGION_AND_BUILDING_DATA.plain,
                        element: <ValidateRoute route={ROUTE_REGION_AND_BUILDING_DATA}><Buildings /></ValidateRoute>,
                    },
                    {
                        path: ROUTE_FY_COST_ESTIMATE.plain,
                        element: <ValidateRoute route={ROUTE_FY_COST_ESTIMATE}><CostEstimates /></ValidateRoute>,
                    },
                    // TODO * make other routes that are associated with eachother in their own folder
                    ...customerRoutes,
                    {
                        path: ROUTE_OCCUPANCY_AGREEMENTS.plain,
                        element: <ValidateRoute route={ROUTE_OCCUPANCY_AGREEMENTS}><OccupancyAgreementPage/></ValidateRoute>,
                        children: [
                            {
                                path: ROUTE_OCCUPANCY_AGREEMENTS_LIST.plain,
                                element: <ValidateRoute route={ROUTE_OCCUPANCY_AGREEMENTS_LIST}><ListOccupancyAgreements/></ValidateRoute>,
                                children: [
                                    {
                                        path: ROUTE_OCCUPANCY_AGREEMENTS_UPDATE.plain,
                                        element: <ValidateRoute route={ROUTE_OCCUPANCY_AGREEMENTS_UPDATE}><UpdateOccupancyAgreement/></ValidateRoute>
                                    }
                                ]
                                
                            },
                            
                        ]
                    },
                    {
                        path: ROUTE_HELP.plain,
                        element: <HelpPage />,
                    },
                    {
                        path: ROUTE_COST_ESTIMATE_REPORT.plain,
                        element: <CostEstimatesReport />,
                    },
                    {
                        path: ROUTE_BUILDING_SPECIFIC_COST_ESTIMATE.plain,
                        // element: <ValidateRoute route={ROUTE_BUILDING_SPECIFIC_COST_ESTIMATE}><GrandTotalFyCostEstimate /></ValidateRoute>
                        element: <BuildingSpecificCostEstimate />
                    },
                    {
                        path: ROUTE_AGENCY_SPECIFIC_COST_ESTIMATE.plain,
                        element: <AgencySpecificCostEstimate />
                    },
                    {
                        path: ROUTE_SPECIFIC_COST_ESTIMATE_SUMMARY.plain,
                        element: <SpecificCostEstimateSummary />
                    },
                    {
                        path: ROUTE_BUILDING_SPECIFIC_ESTIMATE_REPORT.plain,
                        element: <ValidateRoute route={ROUTE_BUILDING_SPECIFIC_ESTIMATE_REPORT}><TotalBuildingCostReport /></ValidateRoute>,
                    },
                    {
                        path: ROUTE_SIGN_UP.plain,
                        element: <SignUp />,
                    },
                    {
                        path: ROUTE_ADD_SECURITY_COST.plain,
                        element: <ValidateRoute route={ROUTE_ADD_SECURITY_COST}><AddSecurityCost /></ValidateRoute>,
                    },
                    {
                        path: ROUTE_AUDIT_REPORT.plain,
                        element: <ValidateRoute route={ROUTE_AUDIT_REPORT}><AuditReport /></ValidateRoute>
                    },
                    {
                        path: ROUTE_USER_REPORT.plain,
                        element: <ValidateRoute route={ROUTE_USER_REPORT}><UserReport /></ValidateRoute>,
                    },
                    {
                        path: ROUTE_USERS.plain,
                        element: <ValidateRoute route={ROUTE_USERS}><UsersPage /></ValidateRoute>,
                        loader: () => defer({ userPromise: UserLoader() }),
                    },
                    {
                        path: ROUTE_ROLES.plain,
                        element: <ValidateRoute route={ROUTE_ROLES}><RolesPage /></ValidateRoute>,
                        loader: () => defer({ rolesPromise: RoleLoader() }),
                    },
                    {
                        path: ROUTE_ACTIVE_SESSIONS.plain,
                        element: <ValidateRoute route={ROUTE_ACTIVE_SESSIONS} ><ActiveSessionsPage /></ValidateRoute>,
                        loader: () => defer({ activeSessionsPromise: ActiveSessionsLoader() }),
                    },
                    {
                        path: ROUTE_ADD_USER.plain,
                        element: <ValidateRoute route={ROUTE_ADD_USER}><AddNewUser /></ValidateRoute>,
                    },
                    {
                        path: ROUTE_EDIT_USER.plain,
                        element: <ValidateRoute route={ROUTE_EDIT_USER}><UpdateUser /></ValidateRoute>
                    },
                    {
                        path: ROUTE_EDIT_BUILDING.plain,
                        element: <ValidateRoute route={ROUTE_EDIT_BUILDING}><BuildingsUpdate /></ValidateRoute>
                    },
                    {
                        path: ROUTE_BILLING_ADJUSTMENTS.plain,
                        element: <ValidateRoute route={ROUTE_BILLING_ADJUSTMENTS}><BillingAdjustments /></ValidateRoute>
                    },
                    {
                        path: ROUTE_ADD_ADJUSTMENTS.plain,
                        element: <ValidateRoute route={ROUTE_ADD_ADJUSTMENTS}><AddAdjustment /></ValidateRoute>
                    },
                    {
                        path: ROUTE_EDIT_ADJUSTMENTS.plain,
                        element: <ValidateRoute route={ROUTE_EDIT_ADJUSTMENTS}><EditAdjustment /></ValidateRoute>
                    },
                    {
                        path: ROUTE_CRMA_UPLOAD.plain,
                        element: <ValidateRoute route={ROUTE_CRMA_UPLOAD}><CRMA_UploadPage /></ValidateRoute>
                    },
                    {
                        path: ROUTE_CRMA_DOWNLOAD.plain,
                        element: <CRMA_DownloadPage />
                    },
                    {
                        path: ROUTE_CRMA_HISTORY_GENERATED_BILL_REGION.plain,
                        element: <ValidateRoute route={ROUTE_CRMA_HISTORY_GENERATED_BILL_REGION}><CRMA_HistoryBillGeneration route={ROUTE_CRMA_HISTORY_GENERATED_BILL_REGION.routeId} /></ValidateRoute>
                    },
                    {
                        path: ROUTE_CRMA_HISTORY_GENERATED_BILL_GSA.plain,
                        element: <ValidateRoute route={ROUTE_CRMA_HISTORY_GENERATED_BILL_GSA}><CRMA_HistoryBillGeneration route={ROUTE_CRMA_HISTORY_GENERATED_BILL_GSA.routeId} /></ValidateRoute>
                    },
                    {
                        path: ROUTE_CRMA_HISTORY_GENERATED_BILL_BFC.plain,
                        element: <ValidateRoute route={ROUTE_CRMA_HISTORY_GENERATED_BILL_BFC}><CRMA_HistoryBillGeneration route={ROUTE_CRMA_HISTORY_GENERATED_BILL_BFC.routeId} /></ValidateRoute>
                    },
                    {
                        path: ROUTE_CRMA_REPORT_DATA.plain,
                        element: <ValidateRoute route={ROUTE_CRMA_REPORT_DATA}><CRMA_ReportData /></ValidateRoute>
                    },
                    {
                        path: ROUTE_ADJUSTMENTS_REPORT.plain,
                        element: <ValidateRoute route={ROUTE_ADJUSTMENTS_REPORT}><AdjustmentsReport /></ValidateRoute>
                    },
                    {
                        path: ROUTE_WORKFLOW_TASKS.plain,
                        element: <ValidateRoute route={ROUTE_WORKFLOW_TASKS}><WorkflowTaskList /></ValidateRoute>
                    },
                ]
            },
        ],
    }
]);
// see below for a defer loader pattern
// <React.Suspense><Await>{children}</Await></React.Suspense>
// see https://blog.logrocket.com/complete-guide-authentication-with-react-router-v6/ for a good guide