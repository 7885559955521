import React from 'react';
import { CheckBox } from 'devextreme-react';
import './FormCheckBox.scss';
export const FormCheckBox = ({
  value,
  isEditing,
  name,
  label,
  onValueChange,
}) => {
  return (
    <CheckBox
        value={value}
        name={name}
        text={label}
        readOnly={!isEditing}
        onValueChange={onValueChange}
    />
  );
};