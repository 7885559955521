const PostExhibitStatuses = {
  1: "Active",
  2: "Draft",
  3: "Expired",
  6: "Actual"
};

/**
* Get the PostExhibitStatusId by PostExhibitStatusName.
* @param {string} statusName - The name of the PostExhibitStatus.
* @returns {number|null} The PostExhibitStatusId if found, otherwise null.
*/
export const getPostExhibitStatusIdByName = (statusName) => {
  return parseInt(
      Object.keys(PostExhibitStatuses).find((key) => PostExhibitStatuses[key] === statusName),
      10
  ) || null;
};

/**
* Get the PostExhibitStatusName by PostExhibitStatusId.
* @param {number} statusId - The ID of the PostExhibitStatus.
* @returns {string|null} The PostExhibitStatusName if found, otherwise null.
*/
export const getPostExhibitStatusNameById = (statusId) => PostExhibitStatuses[statusId] || null;

export default PostExhibitStatuses;
