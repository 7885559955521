import classNames from 'classnames'
import Form, {
    Item as FormItem,
    GroupItem,
    ColCountByScreen
} from 'devextreme-react/form';
import {
    Customer,
    Location,
} from '../../../types/customer';
import { FormTextBox } from '../../../components/FormTextBox/FormTextBox';
import { FormCheckBox } from '../../../components/FormCheckBox/FormCheckBox';
import { PatternRule, StringLengthRule } from 'devextreme-react/cjs/validator';
import { FormSelectBox } from '../../../components/FormSelectBox/FormSelectBox';
import { usStates } from '../../util/StateAbbreviations';

/** @typedef {import('../../../types/customer').Customer} Customer */
/** @typedef {import('../../../types/customer').Location} Location */

/**
 * @param {Customer} data
 * @param {boolean} editing
 * @param {function(field, value): void} updateField
 */

const CustomerFormDetails = ({ data, editing, updateField }) => {
    //#region Utility Methods
    const setTheZipCode = (field, value) => {
        if (value && value.length === 9 && !isNaN(value)) {
            updateField(field, value.slice(0, 5) + '-' + value.slice(5, 9));
        }
        else {
            updateField(field, value);
        }
    }
    const formInputToUppercase = (field, value) => {
        updateField(field, value.toUpperCase());
    }
    //#endregion Utility Methods

    return (
        <Form className={classNames({ 'plain-styled-form': true, 'view-mode': !editing })}
            labelMode='floating'>
            <GroupItem colCount={4} caption='General Information' >
                <ColCountByScreen xs={4} />
                <FormItem>
                    <FormTextBox
                        label='Agency Name *'
                        value={data.AgencyName}
                        isEditing={editing}
                        onValueChange={e => updateField('AgencyName', e)}
                        maxLength={55}
                        required={true}
                    >
                        {/* <PatternRule
                            pattern="^[a-zA-Z0-9]+$"
                            message="No special characters are allowed."
                        /> */}
                    </FormTextBox>
                </FormItem>
                <FormItem>
                    <FormTextBox
                        label='Agency Bureau Name *'
                        value={data.AgencyBureauName}
                        isEditing={editing}
                        onValueChange={e => updateField('AgencyBureauName', e)}
                        maxLength={55}
                        required={true}
                    />
                </FormItem>
                <FormItem>
                    <FormTextBox
                        label='Agency Bureau Short Name *'
                        value={data.AgencyBureauShortName}
                        isEditing={editing}
                        onValueChange={e => updateField('AgencyBureauShortName', e)}
                        maxLength={55}
                        required={true}
                    />
                </FormItem>
                <FormItem>
                    <FormTextBox
                        label='Agency Bureau Code *'
                        value={data.AgencyBureauCode}
                        isEditing={editing}
                        onValueChange={e => updateField('AgencyBureauCode', e)}
                        maxLength={4}
                        required={true}
                    >
                        <PatternRule
                            pattern="^[0-9]+$"
                            message="Only numbers are allowed."
                        />
                        <StringLengthRule
                            message="Must be 4 characters long"
                            min={4}
                        />
                    </FormTextBox>
                </FormItem>
                <FormItem>
                    <FormTextBox
                        label='Address *'
                        value={data.BureauAddress.Address1}
                        isEditing={editing}
                        onValueChange={e => updateField('BureauAddress.Address1', e)}
                        maxLength={35}
                        required={true}
                    />
                </FormItem>
                <FormItem>
                    <FormTextBox
                        label='Address 2'
                        value={data.BureauAddress.Address2}
                        isEditing={editing}
                        onValueChange={e => updateField('BureauAddress.Address2', e)}
                        maxLength={35}
                    />
                </FormItem>
                <FormItem>
                    <FormTextBox
                        label='City Name *'
                        value={data.BureauAddress.CityName}
                        isEditing={editing}
                        onValueChange={e => updateField('BureauAddress.CityName', e)}
                        maxLength={23}
                        required={true}
                    />
                </FormItem>
                <FormItem>
                    <FormSelectBox
                        label='State *'
                        value={data.BureauAddress.State}
                        dataSource={usStates}
                        valueExpr="value"
                        displayExpr="value"
                        // displayExpr={(e) => {
                        //     // console.log("e", e);
                        //     return `${e.id} (${e.value})`;
                        // }}
                        isRequired={true}
                        isEditing={editing}
                        onValueChange={e => updateField('BureauAddress.State', e)}
                    />
                </FormItem>
                <FormItem>
                    <FormTextBox
                        label='Zip Code *'
                        value={data.BureauAddress.ZipCode}
                        isEditing={editing}
                        isRequired={true}
                        maxLength={10}
                        onValueChange={e => setTheZipCode('BureauAddress.ZipCode', e)}
                    >
                        <PatternRule
                            pattern="^[0-9\-]{5}$|^[0-9\-]{10}$"
                            message="Only numbers and dashes are allowed, and the string must be either 5 or 10 characters long."
                        />
                        <StringLengthRule
                            min={5}
                            message="Can't be less than 5 or 9 characters"
                        />
                    </FormTextBox>
                </FormItem>
            </GroupItem>
            <GroupItem colCount={4} caption='Billing Information'>
                <ColCountByScreen xs={4} />
                <FormItem>
                    <FormTextBox
                        label='Billed Agency Bureau Code *'
                        value={data.BilledAgencyBureauCode}
                        isEditing={editing}
                        onValueChange={e => updateField('BilledAgencyBureauCode', e)}
                        maxLength={4}
                        required={true}
                    >
                        <PatternRule
                            pattern="^[0-9]+$"
                            message="Only numbers are allowed."
                        />
                        <StringLengthRule
                            message="Must be 4 characters long"
                            min={4}
                        />
                    </FormTextBox>

                </FormItem>
                <FormItem>
                    <FormTextBox
                        label='Actual Agency Bureau Code *'
                        value={data.ActualAgencyBureauCode}
                        isEditing={editing}
                        onValueChange={e => updateField('ActualAgencyBureauCode', e)}
                        maxLength={4}
                        required={true}
                    >
                        <PatternRule
                            pattern="^[0-9]+$"
                            message="Only numbers are allowed."
                        />
                        <StringLengthRule
                            message="Must be 4 characters long"
                            min={4}
                        />
                    </FormTextBox>
                </FormItem>
                <FormItem>
                    <FormTextBox
                        label='Agency Location Code *'
                        value={data.AgencyLocationCode}
                        isEditing={editing}
                        onValueChange={e => formInputToUppercase('AgencyLocationCode', e)}
                        maxLength={11}
                        required={true}
                    >
                        <PatternRule
                            pattern="^[a-zA-Z0-9]+$"
                            message="No special characters are allowed."
                        />
                    </FormTextBox>
                </FormItem>
                <FormItem>
                    <FormTextBox
                        label='TAS-Treasury Account Symbol *'
                        value={data.TreasuryAccSymbol}
                        isEditing={editing}
                        onValueChange={e => updateField('TreasuryAccSymbol', e)}
                        maxLength={22}
                        required={true}
                    >
                        <PatternRule
                            pattern="^[a-zA-Z0-9]+$"
                            message="Only letters and digits are allowed."
                        />
                    </FormTextBox>
                </FormItem>
                <FormItem>
                    <FormTextBox
                        label='BETC-Business Event Type Code *'
                        value={data.BusinessEventTypeCode}
                        isEditing={editing}
                        onValueChange={e => formInputToUppercase('BusinessEventTypeCode', e)}
                        maxLength={8}
                        required={true}
                    >
                        <PatternRule
                            pattern="^[A-Z]+$"
                            message="Only Capital letters allowed."
                        />
                    </FormTextBox>
                </FormItem>
                <FormItem>
                    <FormTextBox
                        label='Funding Document Number'
                        value={data.FundingDocumentNumber}
                        isEditing={editing}
                        onValueChange={e => formInputToUppercase('FundingDocumentNumber', e)}
                        maxLength={20}
                    >
                        <PatternRule
                            pattern="^[a-zA-Z0-9\-]+$"
                            message="No special characters are allowed, see document format information."
                        />
                    </FormTextBox>
                </FormItem>
                <FormItem>
                    <div style={{
                        fontWeight: 'bold'
                        , fontSize: 10
                        , flex: 1
                        , alignContent: 'right'
                    }}>
                        <div><span>Document # Formats: </span> </div>
                        <div><span>MIPR (12345678)</span></div>
                        <div><span>GINV (X1234-567-890-111222)</span></div>
                        <div><span>SWA (SWA-R2023FRBY124)</span></div>
                    </div>
                </FormItem>
            </GroupItem>
            <GroupItem colCount={4} caption='Additional Information'>
                <ColCountByScreen xs={4} />
                <FormItem>
                    <FormTextBox
                        label='Customer POC First Name'
                        value={data.CustomerPOCFirstName}
                        isEditing={editing}
                        onValueChange={e => updateField('CustomerPOCFirstName', e)}
                        maxLength={50}
                    >
                        <PatternRule
                            pattern="^[a-zA-Z0-9]+$"
                            message="No special characters are allowed."
                        />
                    </FormTextBox>
                </FormItem>
                <FormItem>
                    <FormTextBox
                        label='Customer POC Last Name'
                        value={data.CustomerPOCLastName}
                        isEditing={editing}
                        onValueChange={e => updateField('CustomerPOCLastName', e)}
                        maxLength={50}
                    >
                        <PatternRule
                            pattern="^[a-zA-Z0-9]+$"
                            message="No special characters are allowed."
                        />
                    </FormTextBox>
                </FormItem>
                <FormItem>
                    <FormCheckBox
                        label='POCs To Receive Summary'
                        value={data.POCsToRecieveSummary}
                        isEditing={editing}
                        onValueChange={e => updateField('POCsToRecieveSummary', e)}
                    />
                </FormItem>
                <FormItem>
                    <FormCheckBox
                        label='Send IPAC'
                        value={data.SendIPAC}
                        isEditing={editing}
                        onValueChange={e => updateField('SendIPAC', e)}
                    />
                </FormItem>
            </GroupItem>
        </Form>
    );
};

export default CustomerFormDetails;