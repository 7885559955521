import GetFetch from "../hooks/GetFetch";

const URL_postExhibits = '/v1/PostExhibit/GetPostExhibits';

export const fetchPostExhibits = async () => {
    try {
        const { Errors, Message, Success } =
            await GetFetch(URL_postExhibits);
        if (!Success) {
            throw new Error(Errors?.[0]?.Message || "Failed to fetch Post Exhibits.");
        }

        return Message.map(
            ({
                CLIN,
                PostExhibitId,
                Name,
                TaskOrderId,
                FiscalYear,
                StartDate,
                EndDate,
                ClonedPostExhibitId,
                TaskOrder,
                FeeType: { FeeTypeName } = {},
                PostExhibitStatus,
                Posts
            }) => {
                const {
                    VendorName = null,
                    ContractId = null,
                    RegionId = null,
                    TaskOrderTypeId = null,
                    PostExhibit: {
                        StartDate: PostExhibitStartDate = null,
                        EndDate: PostExhibitEndDate = null,
                        Building: {
                            Name: Building_Name = null,
                            RegionNumber = null,
                            SequenceNumber: BuildingSequenceNumber = null,
                            BuildingId = null
                        } = {}
                    } = {}
                } = TaskOrder || {};

                return {
                    CLIN,
                    PostExhibitId,
                    Name,
                    TaskOrderId,
                    FiscalYear,
                    Building_Name,
                    BuildingId,
                    BuildingSequenceNumber,
                    StartDate,
                    EndDate,
                    RegionId,
                    VendorName,
                    ContractId,
                    PostExhibitStartDate,
                    PostExhibitEndDate,
                    FeeTypeName,
                    PostExhibitStatus,
                    TaskOrderTypeId,
                    ClonedPostExhibitId,
                    Posts
                };
            }
        );
    } catch (error) {
        console.error("Error fetching post exhibits:", error);
        throw error;
    }
}