import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { logExport, useAuth } from "../hooks/useAuth";
import { DataGrid } from "devextreme-react";
import CustomPreparedHeaderCell from "../components/CustomPreparedHeaderCell";
import GetFetch from "../hooks/GetFetch";
import { Column, Export, KeyboardNavigation, Pager, Paging, Scrolling, SearchPanel } from "devextreme-react/cjs/data-grid";
import { allowedPageSizes, pageFormats } from "../components/GlobalDataGridConfigurations";
import ExportGrid from "../components/ExportGrid";

const CustomerBuildings = () => {
    const { customerId } = useParams();
    const [data, setData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const { user } = useAuth();
    useEffect(() => {
        fetchCustomerBuildings(customerId);
    }, []);
    const fetchCustomerBuildings = async (id) => {
        setIsLoading(true);
        const { Success, Message, Errors } = await GetFetch(`/v1/Building/Customer/${id}`);
        if (Success) {
            setData(Message);
            console.log("Message", Message)
        }
        else {
            setError(Errors[0].Message);
        }
        setIsLoading(false);
    }
    const pdfFont = () => { return 7; }
    const wrapPdfCell = () => {
        return true;
    }
    const handleExport = (e) => {
        logExport(user.email);
        ExportGrid(e, `Customer_RMS_Sys_ID_${customerId}_Building_Data`, wrapPdfCell(), pdfFont())
    }
    return (
        <>
            <div>
                <span><strong>Customer Buildings</strong></span>
            </div>
            {data &&
                <span
                    aria-live="polite"
                    aria-atomic="true"
                >
                    <DataGrid
                        columnAutoWidth={true}
                        dataSource={data}
                        onCellPrepared={CustomPreparedHeaderCell}
                        showBorders={true}
                        showColumnLines={true}
                        showRowLines={true}
                        rowAlternationEnabled={true}
                        onExporting={e => handleExport(e)}
                    >
                        <KeyboardNavigation enabled={true} />
                        <SearchPanel visible={true} highlightCaseSensitive={true} />
                        <Scrolling />
                        <Paging defaultPageSize={10} />
                        <Pager
                            showPageSizeSelector={true}
                            allowedPageSizes={allowedPageSizes}
                        />
                        <Column dataField="BuildingId"  visible={false} />
                        <Column dataField="SequenceNumber" caption="Building Number" />
                        <Column dataField="Name" caption="Name" />
                        <Column dataField="RegionNumber" caption="Region Number" />
                        <Column dataField="AdjustedRentSqFt" caption="Adjusted Rent Sq Ft" />
                        <Column dataField="AdjustedUsageSqFt" caption="Adjusted Usage Sq Ft" />
                        <Column dataField="AnsiRentSqFt" caption="Ansi Rent Sq Ft" />
                        <Column dataField="AnsiUsableSqFt" caption="Ansi Usable SqFt" />
                        <Column dataField="CommunityJointUseSqFt" caption="Community Joint Use SqFt" />
                        <Column dataField="CongressionalRep" caption="Congressional Representative" />
                        <Column dataField="Facility" caption="Facility" />
                        <Column dataField="address" caption="Address" />
                        <Column dataField="Address1" caption="Address1" visible={false} />
                        <Column dataField="Address2" caption="Address2" visible={false} />
                        <Column dataField="CityName" caption="CityName" visible={false} />
                        <Column dataField="StateLocation" caption="State" visible={false} />
                        <Column dataField="ZipCode" caption="ZipCode" visible={false} />
                        <Column dataField="Joint Use Sq Ft" caption="JointUseSqFt" />
                        <Column dataField="LeaseJointUseSqFt" caption="Lease Joint Use SqFt" />
                        
                        <Column dataField="RentUsageFactor" caption="Rent Usage Factor" />
                        <Column dataField="Status" caption="Status" />
                        <Export
                            enabled={true}
                            formats={pageFormats}
                        />
                    </DataGrid>
                </span>}
        </>
    )
}
export default CustomerBuildings;