import { ROUTE_CONTRACT_COST_ESTIMATES } from "../routes/Routes";
import { useEffect } from "react";
import { useState } from "react";
import DataGridPostExhibits from "../PostExhibitPage/components/DataGridPostExhibits";
import { LoadingPanel } from "../components/LoadingPanel";
import { fetchPostExhibits } from "../PostExhibitPage/PostExhibitsService";
import IsNullOrEmpty from "../components/IsNullOrEmpty";
import { getPostExhibitStatusIdByName } from "../Enums/postExhibitStatuses";

const ContractCostEstimates = () => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [contractEstimates, setContractEstimates] = useState();

    useEffect(() => {
        loadPostExhibits();
    }, []);

    const loadPostExhibits = async () => {
        setLoading(true);
        try {
            const draftStatusId = getPostExhibitStatusIdByName('Draft');
            const data = await fetchPostExhibits();
            setContractEstimates(data.filter(p =>
                p.PostExhibitStatus?.PostExhibitStatusId === draftStatusId &&
                !IsNullOrEmpty(p.ClonedPostExhibitId)
            ));
        } catch (error) {
            setError(error.message || "Error loading Cost Estimates");
        }
        setLoading(false);
    }

    return (
        <div>
            <h1>{ROUTE_CONTRACT_COST_ESTIMATES.title}</h1>
            {error && <p>Something went wrong.</p>}
            {loading && !contractEstimates && <LoadingPanel location={{ of: '#costEstimate-list' }} message="Loading Estimates List..." />}
            {contractEstimates &&
                <div id="costEstimate-list">
                    <DataGridPostExhibits
                        dataSource={contractEstimates}
                        isCostEstimate={true}
                    />
                </div>
            }
        </div>
    )
}
export default ContractCostEstimates;