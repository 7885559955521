import {
    useEffect,
    useState,
} from 'react';
import classNames from 'classnames'
import Form, {
    Item as FormItem,
    GroupItem,
    ColCountByScreen
} from 'devextreme-react/form';
import {
    Building,
    BuildingByCustomer,
    Customer,
    Location,
    OccupancyAgreement
} from '../types/occupancyAgreement';
import { Endpoints } from '../Enums/endpoints';
import GetFetch from '../hooks/GetFetch';
import { FormTextBox } from '../components/FormTextBox/FormTextBox';
import { FormDateBox } from '../components/FormDateBox/FormDateBox';
import { FormSelectBox } from '../components/FormSelectBox/FormSelectBox';
import { FormNumberBox } from '../components/FormNumberBox/FormNumberBox';

const {
    GET_BUILDING_BY_CUSTOMER_BY_CUSTOMER_ID
} = Endpoints;


/** @typedef {import('../types/occupancyAgreement').Building} Building */
/** @typedef {import('../types/occupancyAgreement').BuildingByCustomer} BuildingByCustomer */
/** @typedef {import('../types/occupancyAgreement').Customer} Customer */
/** @typedef {import('../types/occupancyAgreement').Location} Location */
/** @typedef {import('../types/occupancyAgreement').OccupancyAgreement} OccupancyAgreement */

/**
 * @param {OccupancyAgreement} data
 * @param {boolean} editing
 * @param {function(field, value): void} updateField
 */

const OccupancyFormDetails = ({ data, editing, updateField }) => {
    // console.log("Data at OccupancyFormDetails:", data);
    const [buildingByCustomers, setBuildingByCustomers] = useState([]);
    const [loadingBuildings, setLoadingBuildings] = useState(false);

    useEffect(() => {
        setLoadingBuildings(true);
        fetchAndSet(`${GET_BUILDING_BY_CUSTOMER_BY_CUSTOMER_ID}${data.BuildingByCustomer.CustomerId}`, setBuildingByCustomers)
        setLoadingBuildings(false);
    }, []);

    const fetchAndSet = async (URL, setter) => {
        const { Errors, Message, Success } = await GetFetch(URL);
        if (Success === true) {
            setter(Message);
            console.log(Message);
        }
        else {
            console.error(Errors[0].Message);
        }
    }
    const currentBuildingByCustomer = buildingByCustomers.find(e => e.BuildingByCustomerId === data.BuildingByCustomer.BuildingByCustomerId)

    return (
        <Form className={classNames({ 'plain-styled-form': true, 'view-mode': !editing })}
            labelMode='floating'>
            <GroupItem colCount={4} caption='General Information' >
                <ColCountByScreen xs={4} />
                <FormItem>
                    <FormTextBox
                        label='OA Number'
                        value={data.OANumber}
                        isEditing={!editing}
                        onValueChange={e => updateField('OANumber', e)}
                    />
                </FormItem>
                <FormItem>
                    <FormSelectBox
                        label='Building Number'
                        value={currentBuildingByCustomer}
                        isEditing={!editing}
                        dataSource={buildingByCustomers}
                        
                        valueExpr={data => data}  // Use BuildingByCustomerId as the value
                        displayExpr={"Building.SequenceNumber"}     // Display SequenceNumber as the label
                        onValueChange={e => updateField('BuildingByCustomer', e)}
                    />
                    
                </FormItem>
                <FormItem>
                    <FormTextBox
                        label='Created By'
                        value={data.CreatedBy}
                        isEditing={!editing}
                        readOnly
                    // onValueChange={e => updateField('CreatedBy', e)}

                    />
                </FormItem>
                <FormItem cssClass='accent'>
                    <FormDateBox
                        label='Created Date'
                        value={data.CreatedDate}
                        isEditing={!editing}
                        readOnly
                    // onValueChange={e => updateField('CreatedDate', e)}
                    />
                </FormItem>
                <FormItem>
                    <FormDateBox
                        label='Effective Date'
                        value={data.EffectiveDate}
                        isEditing={!editing}
                        onValueChange={e => updateField('EffectiveDate', e)}
                    />
                </FormItem>
                <FormItem cssClass='accent'>
                    <FormDateBox
                        label='Expiration Date'
                        value={data.ExpirationDate}
                        isEditing={!editing}
                        onValueChange={e => updateField('ExpirationDate', e)}
                    />
                </FormItem>
                <FormItem>
                    <FormTextBox
                        label='Member Name'
                        value={data.MemberName}
                        isEditing={!editing}
                        onValueChange={e => updateField('MemberName', e)}
                    />
                </FormItem>
            </GroupItem>
            <GroupItem colCount={4} caption='Billing Information'>
                <ColCountByScreen xs={4} />
                <FormItem>
                    <FormNumberBox
                        label='Building Ansi Rent'
                        value={data.BuildingAnsiRent}
                        isEditing={!editing}
                        onValueChange={e => updateField('BuildingAnsiRent', e)}
                        format='decimal'
                    />
                </FormItem>
                <FormItem>
                    <FormNumberBox
                        label='Joint Use Rent SqFt'
                        value={data.JURentSqFt}
                        isEditing={!editing}
                        onValueChange={e => updateField('JURentSqFt', e)}
                        format='decimal'
                    />
                </FormItem>
                <FormItem>
                    <FormTextBox
                        label='Lease Number'
                        value={data.LeaseNumber}
                        isEditing={!editing}
                        onValueChange={e => updateField('LeaseNumber', e)}
                    />
                </FormItem>
                <FormItem>
                    <FormNumberBox
                        label='Rent Usage Factor'
                        value={data.RUFactor}
                        isEditing={!editing}
                        onValueChange={e => updateField('RUFactor', e)}
                        // min={0.001}          // Minimum allowed value
                        // max={0.999}          // Maximum allowed value
                        // step={0.001}         // Step size, so it increments by 0.001
                        format='decimal'
                    />
                </FormItem>
                <FormItem>
                    <FormNumberBox
                        label='Total Rent SqFt'
                        value={data.TotalRentSqFt}
                        isEditing={!editing}
                        onValueChange={e => updateField('TotalRentSqFt', e)}
                    />
                </FormItem>

            </GroupItem>
        </Form>
    );
};

export default OccupancyFormDetails;
// bbc
// {
//     "BuildingByCustomerId": 99,
//     "CustomerId": 7,
//     "BuildingId": 293,
//     "IsInactive": false,
//     "CreatedDate": "0001-01-01",
//     "CreatedBy": null,
//     "UpdatedDate": null,
//     "UpdatedBy": null,
//     "Building": {
//         "BuildingId": 293,
//         "SequenceNumber": "1299ZZ",
//         "Name": "bldg test",
//         "Status": null,
//         "Facility": null,
//         "RegionNumber": 1,
//         "Location": null,
//         "RentUsageFactor": 0,
//         "AdjustedRentSqFt": 0,
//         "AdjustedUsageSqFt": 0,
//         "AnsiRentSqFt": 0,
//         "AnsiUsableSqFt": 0,
//         "JointUseSqFt": 0,
//         "CommunityJointUseSqFt": 0,
//         "LeaseJointUseSqFt": 0,
//         "CongressionalRep": "",
//         "Version": "ODYxOWYyMmRiMjg1Njk1NzAyOTliNmJjZGNkMmFiM2U="
//     },
//     "Customer": {
//         "CustomerId": 7,
//         "AgencyName": "Test Agency 04",
//         "AgencyBureauName": "Test Agency Bureau 04",
//         "AgencyBureauShortName": null,
//         "AgencyBureauCode": null,
//         "BilledAgencyBureauCode": "0987",
//         "ActualAgencyBureauCode": "0987",
//         "AgencyLocationCode": "ALC12345",
//         "TreasuryAccSymbol": "T",
//         "BusinessEventTypeCode": "T",
//         "CustomerPOCFirstName": "Tes",
//         "CustomerPOCLastName": "Ting",
//         "POCsToRecieveSummary": true,
//         "SendIPAC": true,
//         "FundingDocumentNumber": null,
//         "BureauAddress": null,
//         "CreatedBy": null,
//         "CreatedDate": "0001-01-01",
//         "UpdatedBy": null,
//         "UpdatedDate": "0001-01-01",
//         "Buildings": null,
//         "BuildingByCustomer": null,
//         "OccupancyAgreements": null
//     },
//     "OccupancyAgreement": null
// }