import React from 'react';
import SelectBox from 'devextreme-react/select-box';
import Validator, { RequiredRule } from 'devextreme-react/validator';
import './FormSelectBox.scss';

/* type SelectBoxProps = {
  value: string,
  label?: string,
  isEditing: boolean,
  onValueChange: (data) => void,
} */
/**
 * @typedef {object} SelectBoxProps
 * @property {string} value
 * @property {string?} label
 * @property {boolean} isEditing
 * @property {function(data): void} onValueChange
 */

/**
 * @param {SelectBoxProps} data
 */
export const FormSelectBox = ({
    value,
    label,
    isEditing,
    onValueChange,
    dataSource,
    readOnly = false,
    isRequired = false,
    valueExpr,
    displayExpr,
    children
}) => {
    const isReadOnly = (readOnly, isEditing) => {
        if (readOnly) {
            return readOnly;
        }
        return !isEditing;
    };

    // Render a SelectBox with options where `SequenceNumber` is shown and `BuildingByCustomerId` is used as the value
    return (
        <div className="form-selectbox">
            <SelectBox
                label={label}
                value={value}
                dataSource={dataSource}
                valueExpr={valueExpr}  // Use BuildingByCustomerId as the value
                displayExpr={displayExpr}    // Display SequenceNumber as the label
                readOnly={isReadOnly(readOnly, isEditing)}
                elementAttr={{ class: 'form-editor' }}
                inputAttr={{ class: 'form-editor-input' }}
                stylingMode="filled"
                onValueChange={onValueChange}
            >
            {/* Optional: Validator if the field is required */}
            {isRequired && 
                <Validator>
                    <RequiredRule message="This field is required." />
                    {children}
                </Validator>
            }
            </SelectBox>
        </div>
    );
};