import React, { useEffect, useState } from 'react';
import CustomersTable from './CustomersTable';
import { useAuth } from '../hooks/useAuth';
import './css/Customers.scss';
import { ROUTE_CUSTOMERS } from '../routes/Customer/Routes';
import { BaseButton } from '../components/BaseButton/BaseButton';
import { useNavigate } from 'react-router-dom';

const Customers = () => {
  const [forceReload, setForceReload] = useState(false);
  const { user } = useAuth();
  const [allowEditing, setAllowEditing] = useState(false);
  const [allowCreate, setAllowCreate] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    const permissions = user.role.ModulePermissions?.filter(module => { return (module.Page === ROUTE_CUSTOMERS.module) })
    setAllowEditing(permissions?.filter(module => { return (module.Update) }).length > 0)
    setAllowCreate(permissions?.filter(module => { return (module.Create) }).length > 0)
  }, [])

  const handleAddCustomer = () => {
    navigate('Create');
  }
  return (
    <div>
      
      {allowCreate && <BaseButton
        label="Add a Customer"
        onClick={handleAddCustomer}
        variant="outlined"
      />}

      {/* {allowEditing && <BaseButton
          ariaDisabled={isRecordSelected}
          disabled={isRecordSelected}
          label={editCustomerButtonLabel}
          onClick={handleEditButtonClick}
          variant={outlined}
        />} */}
      <CustomersTable forceReload={forceReload} stopForceReload={() => { setForceReload(false) }} allowEditing={allowEditing} allowCreate={allowCreate} />
      <br />
    </div>
  );
}

export default Customers;
