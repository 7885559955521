import { CheckBox } from "devextreme-react";
import "./BaseCheckBox.scss";

/**
 * 
 * @param BaseTextBox 
 * This is the base of a BaseInputSelectField for RMS.
 * It uses a SelectBox as the root UI Component from DevExtreme, and is used 
 * strings of text to store options of values that can be selected, one at time.
 * 
 */

export const BaseCheckBox = (
/**
 * @param {Object} elementAttr: Sets any global HTML attribute to the component. Optional. Defualts to {}.
 * @param {Boolean} enableThreeStateBehavior: Specifies whether uerses can set check box state to indeterminate. Defaults to false.
 * @param {Boolean} focusStateEnabled: Specifies whether the UI component can be focused using keyboard navigation (desktop). Optional. Defualts to true.
 * @param {Function} onValueChanged: A function that updates the value of the check box with a new value.
 * @param {String} text: Specifies the visible text by the check box. Defaults to "".
 * @param {Boolean | null | undefined} value: Sets the initial state for the check box. Value can be checked (true), unchecked (false), or indeterminate (undefined or null). Indeterminate can be set only when `enableThreeStateBehavior` is true. Defaults to false.
 * @param {Boolean | null | undefined} readOnly: Sets the initial state for the check box. Value can be checked (true), unchecked (false), or indeterminate (undefined or null). Indeterminate can be set only when `enableThreeStateBehavior` is true. Defaults to false.
 */
    {
        elementAttr,
        onCheckedValueChanged,
        text,
        value,
        readOnly
    }
) => {
    return (
        <span className="inline__span--checkbox-wrapper">
            <CheckBox 
                elementAttr={elementAttr}
                enableThreeStateBehavior={false}
                focusStateEnabled={true}
                onValueChanged={onCheckedValueChanged}
                text={text}
                value={value}
                readOnly={readOnly}
            />
        </span>
    );
};