import React from 'react';
import Toolbar, { Item } from 'devextreme-react/toolbar';
import './toolbar-form.scss';
import { ToolbarButton } from '../toolbar-button/toolbar-button';

export const ToolbarForm = ({ editing, toggleEditing, onCancelClick, onSaveClick, onBackClick, canSave }) => {

  return (
    <Toolbar className='toolbar-form'>
      <Item location='after' visible={!editing}>
        <ToolbarButton
          label='Edit'
          icon='edit'
          variant='contained'
          ariaLabel='edit-form-button'
          ariaDisabled={!editing}
          onClick={toggleEditing}
          noStyle={true}
        />
      </Item>
      <Item location='after' visible={!editing}>
        <ToolbarButton
          label='Back'
          icon='save'
          variant='outlined'
          ariaLabel='back-out-of-form-button'
          ariaDisabled={!editing}
          onClick={onBackClick}
          noStyle={true}
        />
      </Item>
      <Item location='after' visible={editing}>
        <ToolbarButton
          label='Save'
          icon='save'
          variant='contained'
          ariaLabel='save-form-button'
          ariaDisabled={!editing}
          onClick={onSaveClick}
          noStyle={true}
        />
      </Item>
      <Item location='after' visible={editing}>
        <ToolbarButton
          label='Cancel'
          icon='cancel'
          variant='outlined'
          ariaLabel='cancel-edit-form-button'
          ariaDisabled={!editing}
          onClick={onCancelClick}
          noStyle={true}
        />
      </Item>
    </Toolbar>
  );
};