import React from 'react';
import UpdateCustomer from './UpdateCustomer';
import CustomerBuildings from './CustomerBuildings';

const CustomerDetails = () => {
    
    return (
        <>
            <h4>Customer Details</h4>
            <UpdateCustomer />
            {/* Buildings will be shown here */}
            <CustomerBuildings/>
        </>
    );
}
export default CustomerDetails;
