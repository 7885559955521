import {
    useEffect,
    useRef,
    useState
} from "react";
import { useNavigate } from "react-router-dom";
import CustomerFormDetails from "./CustomerFormDetails";
import ValidationGroup from 'devextreme-react/validation-group';
import { ToolbarForm } from "../../../components/toolbar-form/toolbar-form";
import './CustomerForm.scss';
import PutPostPatchFetch from "../../../hooks/PutPostPatchFetch";
import ClosingAlert from "../../../components/ClosingAlert";
import IsNullOrEmpty from "../../../components/IsNullOrEmpty";

const CustomerForm = ({ data, isLoading = false, create = false }) => {
    const [editing, setEditing] = useState(create);
    const [formData, setFormData] = useState(data);
    const navigate = useNavigate();
    const dataRef = useRef(null);
    const validationGroupRef = useRef(null);
    const navigateToRoute = () => navigate(-1);
    const [customerSaved, setCustomerSaved] = useState(false);
    const [customerSaveFail, setCustomerSaveFail] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [codeMismatchAlert, setCodeMismatchAlert] = useState(false);
    const saveEndpoint = create ? '/v1/Customer/AddCustomer' : '/v1/Customer';
    const saveMethod = create ? 'POST' : 'PUT';

    useEffect(() => {
        setFormData(data);
        if (create) {
            dataRef.current = formData;
        }
    }, [data]);

    useEffect(() => {
        if (isValidCode(formData.BilledAgencyBureauCode)
            && isValidCode(formData.ActualAgencyBureauCode)) {
            setCodeMismatchAlert(formData.BilledAgencyBureauCode != formData.ActualAgencyBureauCode)
        }
    }, [
        formData.BilledAgencyBureauCode,
        formData.ActualAgencyBureauCode
    ]);
    const isValidCode = (input) => {
        if (IsNullOrEmpty(input)) {
            return false;
        }
        if (input.length !== 4) {
            return false;
        }
        if (/^[A-Za-z0-9]*$/.test(input) !== true) {
            return false;
        }
        return true;
    };
    const handleEditClick = () => {
        if (!editing && formData) {
            dataRef.current = formData;
        }
        else {
            dataRef.current = undefined;
        }
        setEditing(!editing);
    };
    const onSaveClick = async () => {
        const { isValid, brokenRules } = validationGroupRef.current.instance.validate();
        if (brokenRules) {
            console.warn(`${brokenRules.length} rules have failed due to user input.`)
        }
        if (!isValid) {
            return;
        }
        setCustomerSaveFail(false);
        setCustomerSaved(false);
        setErrorMessage("");
        const { Success, Message, Errors } = await PutPostPatchFetch(saveEndpoint, saveMethod, formData);
        if (Success) {
            // method successful but may still return "false" where endpoint wasn't able to save/add record
            if (Message) {
                setCustomerSaved(true);
                setCustomerSaveFail(false);
                setErrorMessage("");
            }
            else {
                setErrorMessage("We were unable to save this information. Please try again or contact your system's administrator");
                setCustomerSaved(false);
                setCustomerSaveFail(true);
            }

        }
        else {
            // 400s to 500s status codes
            console.log("What went wrong:", Errors);
            setErrorMessage(Errors[0].Message);
            setCustomerSaved(false);
            setCustomerSaveFail(true);
        }
        setEditing(false);
    };

    const onCancelClick = () => {
        setFormData(dataRef.current);
        handleEditClick();
    };
    const onBackClick = () => {
        navigate(-1);
    }
    const updateField = (field, value) => {
        const addressObject = "BureauAddress";
        if (!formData)
            return;
        if (field.includes(addressObject)) {
            var parts = field.split(".");
            var newField = parts[1];
            formData[addressObject][newField] = value;
            const updatedParent = {
                ...formData[addressObject],        // Copy the parent object 
                [newField]: value                // Update the specific nested field 
            };
            setFormData({
                ...formData,
                [addressObject]: updatedParent,    // Set the updated parent object back in formData
            });
        }
        else {
            formData[field] = value;
            setFormData({ ...formData, ...{ [field]: value } });
        }

    };
    return (
        <>
            <div className='customer-form'>
                <ValidationGroup ref={validationGroupRef}>
                    <ToolbarForm
                        toggleEditing={handleEditClick}
                        onSaveClick={onSaveClick}
                        editing={editing}
                        onCancelClick={onCancelClick}
                        onBackClick={onBackClick}
                    />
                    <CustomerFormDetails
                        loading={isLoading}
                        hasData={!formData}
                        data={formData}
                        editing={editing}
                        updateField={updateField}
                    />
                </ValidationGroup>
            </div>
            <ClosingAlert
                clickableIcon={navigateToRoute}
                enableAlertIcon={true}
                message={`Customer was saved successfully. Click the "Forward" icon to be routed back to Customers Page.`}
                severity="success"
                visible={customerSaved}
            />
            <ClosingAlert
                enableAlertIcon={true}
                clickableIcon={() => { setCustomerSaveFail(false) }}
                message={errorMessage}
                severity="warning"
                visible={customerSaveFail}
            />
            <ClosingAlert
                clickableIcon={() => { setCodeMismatchAlert(false) }}
                enableAlertIcon={true}
                message={"There appears to be a mismatch between the billed and actual agency bureau codes. If this is correct, please ignore this message and continue."}
                severity="info"
                visible={codeMismatchAlert}
            />
        </>
    )
}
export default CustomerForm;