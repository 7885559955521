import { Button } from "devextreme-react/button";
import "./toolbar-button.scss";

/**
 * 
 * @module ToolbarButton
 * This is the base of a button for RMS.
 * It uses a MUI Button as the root UI component.
 * This button should be used in the case of routing a 
 * user from one page-level UI component in RMS to another.
 * 
 */

export const ToolbarButton = ({
    /**
     * @param {Boolean} aria_disabled: sets the value for aria-disabled based on the disabled/enabled state of the button.
     * @param {Boolean} disabled: disables the button from user input when set to `true`. Optional. Defaults to false.
     * @param {String} label: the visible label (text) for the button.
     * @param {Function} onClick: accepts a function, but more importantly takes a method for performing page routing.
     * @param {String} variant: accepts one of three button variants to be used; 'contained', 'outlined, 'text' (literal union). Optional. Defaults to "".
     */
    ariaDisabled,
    disabled,
    ariaLabel,
    variant,
    onClick,
    label,
    icon,
    noStyle,
    id
}) => {
    return (
        <>
            {
                noStyle ?
                    <Button
                        id={id}
                        aria-disabled={ariaDisabled}
                        disabled={disabled}
                        aria-label={ariaLabel}
                        onClick={onClick}
                        variant={variant}
                        icon={icon}
                    >
                        {label}
                    </Button>
                    :
                    <span className="inline__button--margin-right">
                        <Button
                            id={id}
                            aria-disabled={ariaDisabled}
                            disabled={disabled}
                            aria-label={ariaLabel}
                            onClick={onClick}
                            variant={variant}
                            icon={icon}
                        >
                            {label}
                        </Button>
                    </span>
            }
        </>
    );
};